
import React from "react";

const AddContent = ({
  content,
  //setIsVisible,
  onClose = () => {},
}) => {
  // const handleClose = () => {
  //   //setIsVisible(false);
  //   onClose();
  // };

  // Extract YouTube video ID from the URL
  const extractYouTubeID = (url) => {
    const regExp =
      /^.*(?:youtu.be\/|v\/|embed\/|watch\?v=|watch\?.+&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[1] ? match[1] : null;
  };

  const youTubeVideoID =
    content.type === "youtube" ? extractYouTubeID(content.src) : null;

  return (
    <div style={{ position: "relative" }}>
      {/* Close Button */}
      <button
        className="btn-close position-absolute top-0 end-0 "
        onClick={onClose}
        aria-label="Close"
        style={{
          zIndex: 10,
          backgroundColor: "#fff",
          borderRadius: "50%",
          opacity: 0.8,
        }}
      ></button>

      {/* Content Rendering */}
      {content ? (
        <div className="p-0">
          {/* Image Content */}
          {content.type === "image" && (
            <img
              src={content.src}
              alt={content.alt || "Advertisement"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}

          {/* Video Content */}
          {content.type === "video/mp4" && (
            <video
              src={content.src}
              controls
              autoPlay={content.autoPlay}
              muted={content.muted}
              loop={content.loop}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}

          {/* YouTube Video Content */}
          {content.type === "youtube" && youTubeVideoID && (
            <div
              style={{
                position: "relative",
                width: "100%",
                paddingBottom: "56.25%", // Aspect ratio 16:9
              }}
            >
              <iframe
                src={`https://www.youtube.com/embed/${youTubeVideoID}?autoplay=${
                  content.autoPlay ? "1" : "0"
                }&mute=${content.muted ? "1" : "0"}&loop=${
                  content.loop ? "1" : "0"
                }&playlist=${youTubeVideoID}`}
                title="YouTube Video"
                allow="autoplay; encrypted-media"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="p-3 text-center">
          <p className="text-muted">Content Not Found</p>
        </div>
      )}
    </div>
  );
};

export default AddContent;

