const { faDownload, faEye, faFilePdf, faFlask } = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");

 export const LabReportsModal = ({ show, onHide, labReports }) => {

    console.log(labReports);
  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{
        display: show ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <FontAwesomeIcon icon={faFlask} className="me-2 text-primary" />
              Lab Reports
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onHide}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-1 row-cols-md-2 g-3">
              {labReports.map((reportUrl, index) => {
                const fileName = reportUrl.split("/").pop();
                const formattedName = fileName
                  .replace(/_/g, " ")
                  .replace(".pdf", "");

                return (
                  <div className="col" key={index}>
                    <div className="card h-100 border-0 shadow-sm">
                      <div className="card-body d-flex flex-column">
                        <div className="d-flex align-items-center mb-3">
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            className="text-danger me-3 fs-4"
                          />
                          <div className="overflow-hidden">
                            <h6 className="mb-0 text-truncate">
                              {formattedName}
                            </h6>
                            <small className="text-muted d-block text-truncate">
                              {new URL(reportUrl).hostname}
                            </small>
                          </div>
                        </div>
                        <div className="d-flex gap-2 mt-auto">
                          <button
                            className="btn btn-outline-primary btn-sm flex-grow-1"
                            onClick={() => window.open(reportUrl, "_blank")}
                          >
                            <FontAwesomeIcon icon={faEye} className="me-2" />
                            View
                          </button>
                          <button
                            className="btn btn-outline-success btn-sm flex-grow-1"
                            onClick={() => {
                              const link = document.createElement("a");
                              link.href = reportUrl;
                              link.download = fileName;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onHide}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
