import { useEffect, useState } from "react";
import { ACCESS_TOKEN, DATA_SAVED, ERROR_MSG } from "../../Constant";
import moment from "moment";
import { Modal } from "bootstrap";
import apiHelper from "../../report/ApiHelpar/apiHelper";
import {
  ADD_EMPLOYEE,
  GET_ALL_DEPARTMENT,
  GET_ALL_SHIFT,
  MARK_ATTENDANCE,
} from "../../report/visitConstant/ApiConstant";
import { formatTime } from "./AddNewEmployee";
import ToastFist from "../../report/Toast/ToastFist";
import { removeEmptyValues } from "../../report/ReduxApi/ReduxApi";
import DepartmentSelector from "./DepartmentSlector";
import ShiftSelector from "./ShiftSelector";
import { Color } from "../../report/visitConstant/Color";

const EditEmployee = ({
  fetchRootData,
  shiftItems,
  departmentItems,
  editObjEmpl,
  isAttendanceCall = false,
  onClose,
  activeDate,
  isDeletePress,
}) => {
  const editableEmpl = isAttendanceCall ? editObjEmpl?.employee : editObjEmpl;
  const [activeShiftItems, setActiveShiftItems] = useState(shiftItems);

  const [attendanceState, seAttendanceState] = useState(false);

  const [inputValue, setInputValue] = useState({
    id: editableEmpl?.id || null,
    activeStatus: editableEmpl?.activeStatus,
    name: editableEmpl?.name || "",
    dob: editableEmpl?.dob || "",
    mobileNumber: editableEmpl?.mobileNo || "",
    jobTittle: editableEmpl?.designation || "",
    department: editableEmpl?.department?.id || "",
    departmentName: editableEmpl?.department?.name || "",
    joiningDate: editableEmpl?.joiningDate || "",
    leavingDate: editableEmpl?.leavingDate || "",
    netSalary: editableEmpl?.netSalary !== 0 ? editableEmpl?.netSalary : "",
    grossSalary:
      editableEmpl?.grossSalary !== 0 ? editableEmpl?.grossSalary : "",
    branchId: null,
    totalHour: editableEmpl?.shift?.totalHours || "",
    shift: editableEmpl?.shift?.id || "",
    address: null,
    punchIn: isAttendanceCall
      ? editObjEmpl?.attendance[0]?.checkInTime || ""
      : null,
    punchOut: isAttendanceCall
      ? editObjEmpl?.attendance[0]?.checkOutTime || ""
      : null,
    notes: isAttendanceCall
      ? editObjEmpl?.attendance[editObjEmpl?.attendance?.length - 1]?.notes ||
        ""
      : "",
    ot: isAttendanceCall ? editObjEmpl?.overtimeHours || "" : null,
    date: isAttendanceCall
      ? editObjEmpl?.attendance[0]?.date || activeDate
      : null,
  });
  // console.log(inputValue, "updated value");
  const [loading, setLoading] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [colorStatus, setColorStatus] = useState(3);
  const [showToast, setShowToast] = useState(false);
  const [isrequired, setIsrequired] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log("name -> ", name, "value -> ", value);
    if (name === "mobileNumber" && value.length > 10) return;
    if (name === "department") {
      setInputValue((prev) => ({
        ...prev,
        ["department"]: value?.id,
        ["departmentName"]: value?.name,
      }));
      setAssignShift(value?.id);
    } else {
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === "punchIn") {
      seAttendanceState(true);
    }

    if (name === "punchOut") {
      seAttendanceState(false);
    }
  };

  const setAssignShift = (id) => {
    const filterdDep = shiftItems.filter(
      (item) => item?.departmentId?.id == id
    );
    setActiveShiftItems(filterdDep || []);
    if (filterdDep?.length === 0) {
      setInputValue((prev) => ({
        ...prev,
        ["shift"]: "",
      }));
    }
  };

  useEffect(() => {
    setAssignShift(editableEmpl?.department?.id || "");
  }, [editObjEmpl]);

  useEffect(() => {
    if (onClose) {
      document.head.style.overflow = "hidden";
      document.head.style.paddingRight = "6px";
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "6px";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    }
    return () => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    };
  }, [onClose]);

  // console.log("inp ", inputValue);

  const resetValue = () => {
    setInputValue({
      id: null,
      activeStatus: "",
      name: "",
      dob: "",
      mobileNumber: "",
      jobTitle: "",
      department: "",
      departmentName: "",
      joiningDate: moment().format("YYYY-MM-DD"),
      leavingDate: "",
      netSalary: "",
      grossSalary: "",
      branchId: null,
      totalHour: "",
      shift: "",
      address: "",
      punchIn: "",
      punchOut: "",
      notes: "",
      ot: "",
      date: "",
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    // console.log(inputValue, "entered data ");

    if (isAttendanceCall) {
      if (!inputValue?.notes) {
        setShowToast(true);
        setToastMsg("Pls add notes");
        setColorStatus(3);
        setLoading(false);
        return;
      }
    } else {
      if (!inputValue.name || !inputValue.shift || !inputValue.department) {
        const missingFields = [];
        if (!inputValue.name) missingFields.push("name");
        if (!inputValue.department) missingFields.push("department");
        if (!inputValue.shift) missingFields.push("shift");

        setShowToast(true);
        setToastMsg(
          `Please add the following required fields: ${missingFields.join(
            ", "
          )}`
        );
        setColorStatus(3);
        setLoading(false);

        return;
      }
    }

    try {
      const data = {
        ...(inputValue?.id && { id: inputValue.id }),
        name: inputValue.name.trim(),
        mobileNo: inputValue?.mobileNumber,
        dob: inputValue.dob,
        address: null,
        designation: inputValue.jobTittle,
        joiningDate: inputValue?.joiningDate || null,
        leavingDate: inputValue?.leavingDate,
        activeStatus: inputValue?.leavingDate ? false : true,
        netSalary: inputValue.netSalary
          ? parseFloat(inputValue.netSalary)
          : null,
        grossSalary: inputValue.grossSalary
          ? parseFloat(inputValue.grossSalary)
          : null,
        department: {
          id: inputValue.department ? parseInt(inputValue.department) : null,
          name: inputValue?.departmentName,
        },
        shift: {
          id: inputValue.shift ? parseInt(inputValue.shift) : null,
        },
      };

      const attendanceData = {
        employee: {
          id: inputValue?.id,
          shift: {
            id: inputValue?.shift,
            totalHours: inputValue?.totalHour,
          },
          department: {
            id: inputValue?.department,
            name: inputValue?.departmentName,
          },
        },
        date: inputValue?.date,
        time:
          isAttendanceCall &&
          editObjEmpl?.attendance[0]?.checkInTime == undefined
            ? inputValue?.punchIn
            : inputValue?.punchOut,
        notes: inputValue?.notes?.trim(),
        attendanceState,
      };

      const cleanData = removeEmptyValues(
        isAttendanceCall ? attendanceData : data
      );
      // console.log(data, "passed data ");
      // console.log(cleanData, "passed cleanData ");

      const endPoint = isAttendanceCall ? MARK_ATTENDANCE : ADD_EMPLOYEE;

      const response = await apiHelper(endPoint, cleanData);

      // console.log(response, "response data");

      if (response == false) {
        setShowToast(true);
        setToastMsg(ERROR_MSG);
        setColorStatus(0);
        setLoading(false);
      } else if (response?.respData?.message !== "Data is not saved") {
        setShowToast(true);
        setToastMsg(
          editableEmpl && Object.keys(editableEmpl)?.length > 0
            ? "Data updated successfully"
            : DATA_SAVED
        );
        setColorStatus(1);
        setLoading(false);
        if (fetchRootData) {
          fetchRootData();
        }
        setTimeout(() => {
          resetValue();
          onClose();
        }, 500);
      } else {
        setShowToast(true);
        setLoading(false);
        setToastMsg(ERROR_MSG);
        setColorStatus(0);
      }
    } catch (e) {
      // console.log("skjjs", e);
      setShowToast(true);
      setLoading(false);
      setToastMsg(ERROR_MSG);
      setColorStatus(0);
    }
  };

  // console.log(inputValue, " xx");

  const handleCloseOutsideClick = (e) => {
    // Check if the click is outside the modal content
    if (e.target.classList.contains("modal")) {
      onClose();
    }
  };

  return (
    <div
      className="modal fade show "
      style={{
        display: "block",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      tabIndex="-1"
      role="dialog"
      onClick={handleCloseOutsideClick}
    >
      {/* modal-dialog modal-xl */}
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg ">
        <div className="modal-content ">
          <div className="modal-header border-0 bg-white">
            <h5 className="modal-title" id="AddEmployeelLabel">
              {editableEmpl && Object.keys(editableEmpl)?.length > 0
                ? editableEmpl?.name +
                  (isAttendanceCall && inputValue?.date
                    ? `  (${moment(inputValue?.date).format("DD-MMM-YYYY")})`
                    : "")
                : "Add Staff"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                resetValue();
                onClose();
              }}
            ></button>
          </div>
          <div className="modal-body bg-white ">
            <form>
              <div class="row row-cols-3">
                <div className="col-12 col-sm-6 col-md-4 ">
                  <label htmlFor="name" className="col-form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter name"
                    value={inputValue.name}
                    onChange={handleInputChange}
                    disabled={isAttendanceCall || isDeletePress}
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <div className="">
                    <label htmlFor="mobileNumber" className="col-form-label">
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobileNumber"
                      name="mobileNumber"
                      placeholder="Enter 10 digit number"
                      maxLength={10}
                      value={inputValue.mobileNumber}
                      onChange={handleInputChange}
                      disabled={isAttendanceCall || isDeletePress}
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <div className="">
                    <label htmlFor="dob" className="col-form-label">
                      DOB
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dob"
                      name="dob"
                      placeholder="DD-MM-YYYY"
                      value={inputValue.dob}
                      onChange={handleInputChange}
                      disabled={isAttendanceCall || isDeletePress}
                    />
                  </div>
                </div>
                {/* 
                           <div class="col-8 mt-2">
                             <label htmlFor="address" className="col-form-label">
                               Address
                             </label>
                             <textarea
                               className="form-control"
                               id="address"
                               name="address"
                               placeholder="Enter address"
                               rows={1}
                               value={inputValue.address}
                               onChange={handleInputChange}
                               //   style={{ height: "100px" }}
                             ></textarea>
                           </div> */}
                {/* <div className="mt-2">
                  <label htmlFor="jobTittle" className="col-form-label">
                    Job Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="jobTittle"
                    name="jobTittle"
                    placeholder="Enter job tittle"
                    value={inputValue.jobTittle}
                    onChange={handleInputChange}
                    disabled={isAttendanceCall || isDeletePress}
                  />
                </div> */}
                <div className="col-12 col-sm-6 col-md-4">
                  <DepartmentSelector
                    slectedValue={inputValue?.department}
                    handleInputChange={handleInputChange}
                    departmentItems={departmentItems}
                    inputName={"department"}
                    isDisabled={isAttendanceCall || isDeletePress}
                  />
                </div>

                <ShiftSelector
                  slectedValue={inputValue?.shift}
                  handleInputChange={handleInputChange}
                  shiftItems={activeShiftItems}
                  inputName={"shift"}
                  isDisabled={isAttendanceCall || isDeletePress}
                />
              </div>
              <div class="">
                <div class="row row-cols-3">
                  <div className="col-12 col-sm-6 col-md-4 mt-2">
                    <label htmlFor="jobTittle" className="col-form-label">
                      Job Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="jobTittle"
                      name="jobTittle"
                      placeholder="Enter job tittle"
                      value={inputValue.jobTittle}
                      onChange={handleInputChange}
                      disabled={isAttendanceCall || isDeletePress}
                    />
                  </div>
                  {/* <DepartmentSelector
                    slectedValue={inputValue?.department}
                    handleInputChange={handleInputChange}
                    departmentItems={departmentItems}
                    inputName={"department"}
                    isDisabled={isAttendanceCall || isDeletePress}
                  /> */}

                  <div class="col-12 col-sm-6 col-md-4 mt-2">
                    <div className="">
                      <label htmlFor="joiningDate" className="col-form-label">
                        Joining Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="joiningDate"
                        name="joiningDate"
                        placeholder="DD/MM/YYYY"
                        value={inputValue.joiningDate}
                        onChange={handleInputChange}
                        disabled={isAttendanceCall || isDeletePress}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mt-2">
                    <div className="">
                      <label htmlFor="leavingDate" className="col-form-label">
                        Leaving Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="leavingDate"
                        name="leavingDate"
                        placeholder="DD/MM/YYYY"
                        value={inputValue.leavingDate}
                        onChange={handleInputChange}
                        disabled={isAttendanceCall || !isDeletePress}
                      />
                    </div>
                  </div>

                  <div class="col-12 col-sm-6 col-md-4 mt-2">
                    <div className="">
                      <label htmlFor="grossSalary" className="col-form-label">
                        Total Salary
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="grossSalary"
                        name="grossSalary"
                        placeholder="Enter total salary"
                        value={inputValue.grossSalary}
                        onChange={handleInputChange}
                        disabled={isAttendanceCall || isDeletePress}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mt-2">
                    <div className="">
                      <label htmlFor="netSalary" className="col-form-label">
                        Net Salary
                      </label>

                      <input
                        type="number"
                        className="form-control"
                        id="netSalary"
                        name="netSalary"
                        placeholder="Enter net salary"
                        value={inputValue.netSalary}
                        onChange={handleInputChange}
                        disabled={isAttendanceCall || isDeletePress}
                      />
                    </div>
                  </div>
                </div>
                {isAttendanceCall && (
                  <div class="row row-cols-3">
                    <div class="col mt-2">
                      <div className="">
                        <label htmlFor="punchIn" className="col-form-label">
                          Punch In
                        </label>
                        <input
                          type="time"
                          className="form-control"
                          id="punchIn"
                          name="punchIn"
                          placeholder="Select punch-in time"
                          value={inputValue?.punchIn}
                          onChange={handleInputChange}
                          disabled={
                            isAttendanceCall &&
                            editObjEmpl?.attendance[0]?.checkInTime
                          }
                        />
                      </div>
                    </div>
                    <div class="col mt-2">
                      <div className="">
                        <label htmlFor="punchOut" className="col-form-label">
                          Punch Out
                        </label>
                        <input
                          type="time"
                          className="form-control"
                          id="punchOut"
                          name="punchOut"
                          placeholder="Select punch-out time"
                          value={inputValue?.punchOut}
                          onChange={handleInputChange}
                          disabled={
                            isAttendanceCall &&
                            editObjEmpl?.attendance[0]?.checkInTime == undefined
                          }
                        />
                      </div>
                    </div>
                    <div class="col mt-2">
                      <div className="">
                        <label htmlFor="notes" className="col-form-label">
                          Notes
                        </label>
                        <textarea
                          type="text"
                          className="form-control "
                          id="notes"
                          name="notes"
                          maxLength={200}
                          rows={1}
                          placeholder={`Add notes for ${
                            editObjEmpl?.attendance[0]?.checkInTime
                              ? "punch-out"
                              : "punch-in"
                          }`}
                          value={inputValue?.notes}
                          onChange={handleInputChange}
                        />
                      </div>
                      <span style={{ fontSize: 10, color: Color?.gray }}>
                        {inputValue?.notes?.length}/200
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </form>

            {/* <p style={{ margin: 0, color: "red", marginTop: 12, height: 12 }}>
              {isrequired ? "Need to fill atleast name" : ""}
            </p> */}
          </div>

          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn btn-outline-primary fw-medium"
              data-bs-dismiss="modal"
              style={{
                borderRadius: "12px",
                width: 100,
              }}
              onClick={() => {
                resetValue();
                onClose();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary fw-medium"
              // data-bs-dismiss="modal"
              onClick={async () => handleSubmit()}
              disabled={loading}
              style={{
                borderRadius: "12px",
                width: 100,
              }}
            >
              {loading ? (
                <span
                  class="spinner-border spinner-border-sm text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : editableEmpl && Object.keys(editableEmpl).length > 0 ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
        <ToastFist
          showToast={showToast}
          setShowToast={setShowToast}
          title="Indication"
          message={toastMsg}
          duration="Just now"
          status={colorStatus}
        />
      </div>
    </div>
  );
};

export default EditEmployee;
