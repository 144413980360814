import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import TokenCard from "./TokenCard";
import TokenModal from "./TokenModal";
import {
  SESSION_ID,
  BASE_URL,
  getUserTokens,
  Tokan_Type_Generate,
  ROLE,
  update_User_Token,
  USER_ROLE,
  SUPAR_ADMIN,
  ERROR_MSG,
  ROLE_LAB,
  SUPER_ADNIM,
  ACCESS_TOKEN,
} from "../Constant";
import ToastFist from "../report/Toast/ToastFist";

import { fetchVisitPad } from "../report/ReduxApi/VistPadApiCall";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTableList,
  faBorderAll,
  faL,
} from "@fortawesome/free-solid-svg-icons";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { Color } from "../report/visitConstant/Color";
import ModalLabTest from "../repotLabTest/ReportLabTest";
import Advertisement from "../advertisement/Advertisement";
import AddContent from "../advertisement/AddContent";
import apiHelper from "../report/ApiHelpar/apiHelper";
import { Button } from "bootstrap";

export const Roles = [
  // "SUPER_ADMIN",
  // "ROLE_USER",
  //  "ROLE_DOCTOR",
  "ROLE_ADMIN",
  // "ROLE_LAB",
  //  "ROLE_DOCTOR"
];
const Booking = () => {
  const LocalDataTokens = JSON.parse(sessionStorage.getItem("Tokens"));
  const [tokens, setTokens] = useState({
    inQueue: [],
    inProgress: [],
    paymentPending: [],
    complete: [],
    lab: [],
  });

  const [Alltokens, setAllTokens] = useState();
  const [localSerchText, setLocalSerchText] = useState("");
  const [editToken, setEditToken] = useState(null);
  const [labToken, setLabToken] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [isLab, setIsLab] = useState(false);

  const [addData, setAddData] = useState();

  const [adDataclear, setAdDataclear] = useState(false);

  const [loader, setLoader] = useState(() => {
    return sessionStorage.getItem("LoaderStatus") || false;
  });
  const [apiInterval, setApiInterval] = useState(20000);

  //30000
  const [showTime, setShowTime] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [colorStatus, setColorStatus] = useState(3);

  const [screensSplit, setScreenSplit] = useState(false);

  const [FontSize, setFontSize] = useState(() => {
    return sessionStorage.getItem("FontSize") || "m";
  });

  useEffect(() => {
    sessionStorage.setItem("LoaderStatus", loader);
  }, [loader]);
  useEffect(() => {
    sessionStorage.setItem("FontSize", FontSize);
  }, [FontSize]);

  const userRole = useMemo(() => localStorage.getItem(USER_ROLE), []);

  // console.log(userRole ,"userRole----->>>>")
  // const userRole = useMemo(() => (Roles))

  const session = useMemo(() => localStorage.getItem(SESSION_ID), []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(userRole)

  const handleTimeClick = () => setShowTime(!showTime);
  const [view, setView] = useState("grid"); // Default view
  const [bgColor, setBgColor] = useState(""); // Background color state

  useEffect(() => {
    if (userRole?.includes(ROLE) || userRole?.includes(SUPER_ADNIM)) {
      dispatch(fetchVisitPad(navigate));
    }
  }, [dispatch]);

  const fetchTokens = useCallback(async () => {
    // alert("api calling ")
    setLoader(true);
    try {
      const getTokanData = JSON.stringify({
        pubInfo: { sessionId: session },
        request: {
          busiParams: {
            date: moment(new Date()).format("YYYY-MM-DD"),
            type: Tokan_Type_Generate,
            status: "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      const response = await axios.post(
        BASE_URL + getUserTokens,
        getTokanData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      const userTokenList =
        response?.data?.respData?.respMsg?.userTokenList || [];
      // console.log("tokens", response);
      setAllTokens(userTokenList);
      const sortedTokens = {
        inQueue: userTokenList?.filter((token) => token?.status === 1),
        inProgress: userTokenList?.filter((token) => token?.status === 2),
        paymentPending: userTokenList?.filter((token) => token?.status === 4),
        complete: userTokenList?.filter((token) => token?.status === 3),
        lab: userTokenList?.filter((token) => token?.labTest),
      };
      sessionStorage.setItem("Tokens", JSON.stringify(sortedTokens));

      setTokens(sortedTokens);
      //  setTokens(sortedTokens);
      // console.log("----->>>>")
      setLoader(false);
    } catch (error) {
      // console.log(error.message, "fetch");
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
      setLoader(false);
    }
  }, [session]);

  //   const getAllAdvertisementByStatus = async () => {
  //     try {
  //       const endpoint = "getAllAdvertisementByStatus";
  //       const response = await apiHelper(endpoint, {});
  //       // console.log(
  //       //   "API Response:",
  //       //   response.respData.respMsg[response.respData.respMsg.length - 1]
  //       // );
  // //  console.log("API length:", response.respData.respMsg.length - 1);
  //       const data = response.respData.respMsg[response.respData.respMsg.length - 1];
  //        // response.respData.respMsg[0];

  //       if (data.position == "screen-split") {
  //         setFontSize("l");
  //         setScreenSplit(true);
  //       } else {
  //         setScreenSplit(false);
  //       }
  // // console.log(data);
  //       setAddData(data);
  //        //setAddData({ ...data, key: Date.now() });
  //     } catch (error) {
  //          console.log("error");
  //       console.log(error);
  //     }
  //   };

  // if (addData.videoDuration){
  //   if (addData.position !== "screen-split"){
  //  setTimeout(() => {

  //    clearData()
  //  }, addData.videoDuration);
  //   }

  // }

  // const clearData=()=>{
  //    setAddData();
  //    setScreenSplit(false);
  //    setFontSize("m");
  // }

  const parseDuration = (durationString) => {
    // Split the input into minutes and seconds
    const [minutes, seconds] = durationString.split(":").map(Number);

    // Validate the parsed values
    if (isNaN(minutes) || isNaN(seconds)) {
      throw new Error("Invalid duration format. Use 'MM:SS' format.");
    }

    // Convert to milliseconds
    return (minutes * 60 + seconds) * 1000;
  };

  // const getAllAdvertisementByStatus = async () => {
  //   try {
  //     const endpoint = "getAllAdvertisementByStatus";
  //     const response = await apiHelper(endpoint, {});
  //     const data =
  //       response.respData.respMsg?.[response.respData.respMsg.length - 1];

  //     if (data) {
  //       // Determine font size and screen-split status based on position
  //       if (data.position === "screen-split") {
  //         setFontSize("l");
  //         setScreenSplit(true);
  //       } else {
  //         setScreenSplit(false);
  //         setFontSize("m");
  //       }
  //       console.log("data", data);

  //       setAddData(data);

  //       // Handle video duration

  //       if( data?.videoDuration == "0"){
  // console.log(data?.videoDuration);

  //       }else{
  //         if (data?.videoDuration) {
  //           const videoDurationInMs = data?.videoDuration.includes(":")
  //             ? parseDuration(data?.videoDuration) // Parse MM:SS format
  //             : data.videoDuration * 60 * 1000; // Assume minutes if not in MM:SS format

  //           // Set timeout to clear data after video duration
  //           setTimeout(() => {
  //             clearData();

  //             //Check if repeatingTime is valid before re-fetching
  //             if (data?.repeatingTime) {
  //               console.log("data.repeatingTime");
  //               try {
  //                 const repeatingTimeInMs = data?.repeatingTime.includes(":")
  //                   ? parseDuration(data?.repeatingTime) // Parse MM:SS format
  //                   : data.repeatingTime * 60 * 1000; // Assume minutes if not in MM:SS format

  //                 // If repeatingTime is valid, re-fetch advertisement data
  //                 setTimeout(() => {
  //                   getAllAdvertisementByStatus();
  //                 }, repeatingTimeInMs);
  //               } catch (err) {
  //                 console.error("Invalid repeatingTime format:", err?.message);
  //               }
  //             }
  //           }, videoDurationInMs);
  //         }
  //       }
  //     } else {
  //       console.log("No advertisement data available.");
  //     }

  //   } catch (error) {
  //     console.error("Error fetching advertisement data:", error);
  //   }
  // };

  const getAllAdvertisementByStatus = async () => {
    setAdDataclear(false);
    try {
      const endpoint = "getAllAdvertisementByStatus";
      const response = await apiHelper(endpoint, {});
      const data =
        response.respData.respMsg?.[response.respData.respMsg.length - 1];

      if (data) {
        // Determine font size and screen-split status based on position
        if (data.position === "screen-split") {
          setFontSize("l");
          setScreenSplit(true);
        } else {
          setScreenSplit(false);
          setFontSize("m");
        }
        // console.log("data", data);

        setAddData(data);

        // Handle videoDuration
        const isValidVideoDuration =
          data?.videoDuration &&
          typeof data.videoDuration === "string" &&
          data.videoDuration !== "0" &&
          data.videoDuration.trim() !== "";

        if (isValidVideoDuration) {
          const videoDurationInMs = data.videoDuration.includes(":")
            ? parseDuration(data.videoDuration) // Parse MM:SS format
            : Number(data.videoDuration) * 60 * 1000; // Assume minutes if not in MM:SS format

          // Set timeout to clear data after video duration
          setTimeout(() => {
            clearData();

            // Check if repeatingTime is valid before re-fetching
            const isValidRepeatingTime =
              data?.repeatingTime &&
              typeof data.repeatingTime === "string" &&
              data.repeatingTime.trim() !== "";

            if (isValidRepeatingTime) {
              try {
                const repeatingTimeInMs = data.repeatingTime.includes(":")
                  ? parseDuration(data.repeatingTime) // Parse MM:SS format
                  : Number(data.repeatingTime) * 60 * 1000; // Assume minutes if not in MM:SS format

                // If repeatingTime is valid, re-fetch advertisement data
                setTimeout(() => {
                  getAllAdvertisementByStatus();
                }, repeatingTimeInMs);
              } catch (err) {
                console.error("Invalid repeatingTime format:", err?.message);
              }
            } else {
              console.log(
                "repeatingTime is invalid or not provided, skipping re-fetch."
              );
            }
          }, videoDurationInMs);
        } else {
          console.log(
            "videoDuration is invalid or not provided, skipping duration handling."
          );
        }
      } else {
        console.log("No advertisement data available.");
      }
    } catch (error) {
      console.error("Error fetching advertisement data:", error);
    }
  };

  // Function to clear advertisement data
  const clearData = () => {
    // setAddData(null);
    setAdDataclear(true);
    setScreenSplit(false);
    setFontSize("m");
  };

  useEffect(() => {
    if (userRole?.includes(ROLE) || showTime) {
      const interval = setInterval(fetchTokens, apiInterval);
      return () => clearInterval(interval);
    }
  }, [fetchTokens, userRole, showTime, apiInterval]);

  useEffect(() => {
    if (LocalDataTokens) {
      // console.log(LocalDataTokens, "LocalDataTokens" )

      setTokens(LocalDataTokens);
    }
    fetchTokens();
  }, [fetchTokens]);

  useEffect(() => {
    setTimeout(() => {
      getAllAdvertisementByStatus();
    }, 50000);
  }, []);

  // console.log(localStorage.getItem(ACCESS_TOKEN));
  const handleUpdateToken = async (tokenData) => {
    try {
      const updateTokenData = JSON.stringify({
        pubInfo: { sessionId: session },
        request: {
          busiParams: {
            ...tokenData,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // console.log(tokenData, "tokenData");

      const response = await axios.post(
        BASE_URL + update_User_Token,
        updateTokenData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("updated data", response);
      if (response.status === 200) {
        // swal("Success", "User token updated successfully", "success");
        setShowToast(true);
        setColorStatus(1);
        setToastMsg("Token updated successfully");
        fetchTokens();
      }
    } catch (error) {
      ///  console.log(error, "fkkk coming form b");
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };
  // Effect to read the showEdit state from local storage when the component mounts
  useEffect(() => {
    const savedState = localStorage.getItem("showEdit"); // Read from local storage
    const ShowGrid = localStorage.getItem("grid"); // Read from local storage
    if (ShowGrid !== null) {
      setView(JSON.parse(ShowGrid));
      setBgColor(ShowGrid === "grid" ? "bg-primary" : "bg-light");
    }

    if (savedState !== null) {
      setShowEdit(JSON.parse(savedState)); // Set the state from local storage
    }
    if (userRole?.includes(ROLE)) {
      setIsLab(false);
    } else {
      if (userRole?.includes(ROLE_LAB)) {
        setIsLab(true);
      }
    }
  }, []);

  const handleEditToggle = () => {
    const newEditState = !showEdit; // Toggle the state
    setShowEdit(newEditState); // Update state
    localStorage.setItem("showEdit", JSON.stringify(newEditState)); // Update local storage
  };

  const handleViewChange = (selectedView) => {
    setView(selectedView);
    setBgColor(selectedView === "grid" ? "bg-primary" : "bg-light"); // Set background based on view
    localStorage.setItem("grid", JSON.stringify(selectedView));
  };

  const LocalTokanSerch = (e) => {
    setLocalSerchText(e.target.value);
    const lowerCaseKeyword = e.target.value.toLowerCase();

    const serchingdata = Alltokens?.filter((item) => {
      const isNameMatch = item.name.toLowerCase().includes(lowerCaseKeyword);
      const isTokenIdMatch = item.token.toString().includes(lowerCaseKeyword);
      const isMobileNoMatch = item.mobileNo
        .toString()
        .includes(lowerCaseKeyword);

      return isNameMatch || isTokenIdMatch || isMobileNoMatch;
    });

    const sortedTokens = {
      inQueue: serchingdata?.filter((token) => token?.status === 1) || [],
      inProgress: serchingdata?.filter((token) => token?.status === 2) || [],
      paymentPending:
        serchingdata?.filter((token) => token?.status === 4) || [],
      complete: serchingdata?.filter((token) => token?.status === 3) || [],
      lab: serchingdata?.filter((token) => token?.labTest) || [],
    };

    setTokens(sortedTokens);
  };

  const handleFontSizeChange = (value) => {
    setFontSize(value);
  };

  const getGridNum = () => {
    if (FontSize === "s") {
      return "4";
    } else if (FontSize === "l") {
      return "2";
    } else {
      return "3";
    }
  };
  const QueueMsg = "There are no tokens in the inQueue.";
  const CompleteMsg = "There are no tokens in the Complete.";
  const emptyTokensText = (text) => {
    return (
      <p
        style={{ minHeight: 60 }}
        className="alignitems-center text-dark text-center fw-semibold fs-4 "
      >
        {/* There is no tokens for now */}
        {text}
        <FontAwesomeIcon
          className="text-primary ms-2"
          icon="fa-solid fa-circle-nodes"
        />
      </p>
    );
  };

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: "red",
        backgroundColor: "#F5F5F5",
        paddingTop: "110px",
      }}
    >
      <div className="d-flex container ">
        {/* Left section: Tokens and Switch */}
        <div className="flex-grow-1 align-items-center">
          {/* Tokens text for large screens */}
          <div className="d-flex ">
            <p className="m-0  fs-3 fw-bold d-none d-md-block">
              <span onClick={handleTimeClick} className="fw-bold">
                Available Tokens
                {showTime && " :"}
              </span>
            </p>

            {/* Tokens text for small screens */}
            <p className="m-0 mt-1 fs-6 fw-bold d-md-none">
              <span onClick={handleTimeClick} className="fw-bold">
                Available Tokens
                {showTime && " :"}
              </span>
            </p>

            {/* Switch only if userRole matches */}
            {(userRole?.includes(ROLE) || userRole?.includes(SUPER_ADNIM)) && (
              <div className="col-auto">
                <div className="form-check form-switch m-0  ms-sm-4 ms-1 mt-1 ">
                  <input
                    className="form-check-input px-sm-4 py-sm-3"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={showEdit}
                    onChange={handleEditToggle} // Handle checkbox toggle
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right section: View By, Icons and Search */}
        <div className="d-flex align-items-center gap-3">
          {/* Search Bar */}
          <div className="d-none d-sm-block">
            <div
              className="input-group border "
              style={{ width: "313px", borderRadius: 6 }}
            >
              <button
                type="button"
                className="btn btn-outline-primary  border-0 border-start-0 bg-white"
              >
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  className="text-primary"
                />
              </button>

              <input
                className="form-control border-0"
                type="search"
                placeholder="Search name/Token"
                style={{
                  opacity: 0.8,
                  outline: "none",
                  boxShadow: "none",
                }}
                value={localSerchText}
                aria-label="Search"
                onChange={(e) => LocalTokanSerch(e)}
                // style={{ minWidth: "150px", maxWidth: "400px" }} // Adjust min and max width
              />
            </div>
          </div>
          {/* View By Label */}
          {/* <p className="mb-0 fw-semibold text-nowrap">View By</p> */}

          {/* Grid View Icon */}
          <div
            className="d-flex gap-3  p-1 rounded-1"
            style={{ background: "#e8dffc" }}
          >
            <div
              className="cursor-pointer ms-2"
              onClick={() => handleViewChange("grid")}
            >
              <FontAwesomeIcon
                icon={faBorderAll}
                size="lg"
                style={{ color: view === "grid" ? Color.primary : "gray" }} // Active color
              />
            </div>

            {/* Table View Icon */}
            <div
              className="cursor-pointer me-2"
              onClick={() => handleViewChange("table")}
            >
              <FontAwesomeIcon
                icon={faTableList}
                size="lg"
                style={{ color: view === "table" ? Color.primary : "gray" }} // Active color
              />
            </div>
          </div>
          <div className="cursor-pointer  ">
            <select
              className="form-select fw-medium "
              style={{
                outline: "none",
                boxShadow: "none",
              }}
              value={FontSize}
              onChange={(e) => handleFontSizeChange(e.target.value)}
            >
              <option
                value="s"
                style={{ backgroundColor: "white", color: "black" }}
              >
                S
              </option>
              <option
                value="m"
                style={{ backgroundColor: "white", color: "black" }}
              >
                M
              </option>
              <option
                value="l"
                style={{ backgroundColor: "white", color: "black" }}
              >
                L
              </option>
            </select>
          </div>

          {(userRole?.includes(ROLE) || userRole?.includes(SUPER_ADNIM)) && (
            <button
              type="button"
              className="btn btn-outline-primary "
              onClick={() => getAllAdvertisementByStatus()}
            >
              Ad
            </button>
          )}
        </div>
      </div>
      <div
        className="d-sm-none mt-2 mx-3"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div className="input-group  ">
          <button
            type="button"
            className="btn btn-outline-primary  border-0 border-start-0 bg-white "
          >
            <FontAwesomeIcon
              icon={["fas", "search"]}
              className="text-primary"
            />
          </button>
          <input
            className="form-control border-0 text-center"
            type="search"
            placeholder="Search name/Token"
            style={{
              opacity: 0.8,
              outline: "none",
              boxShadow: "none",
            }}
            onChange={(e) => LocalTokanSerch(e)}
            // style={{ Width: "100%" }} // Adjust min and max width
          />
        </div>
      </div>

      <hr
        style={{
          width: "100%",
          // marginLeft: "calc(-50vw + 50%)",
          border: "1px solid gray",
          backgroundColor: "#F5F5F5",
        }}
      />
      <section
        style={{
          backgroundColor: "#F5F5F5",
          minHeight: 600,
          // height: "calc(100vh - 500px)",
        }}
      >
        {/* <ScrollToTopOnMount /> */}
        <div
          className="container "
          style={
            {
              // marginTop: 10,
              // paddingLeft: 50, paddingRight: 50, width: '100%'
            }
          }
        >
          <div class={`row  ${screensSplit && "row-cols-md-2"}  `}>
            <div class="col">
              {isLab
                ? loader && tokens?.lab?.length === 0
                  ? emptyTokensText(QueueMsg)
                  : tokens?.lab?.length === 0 && emptyTokensText(QueueMsg)
                : loader &&
                  tokens?.inProgress?.length === 0 &&
                  tokens?.inQueue?.length === 0
                ? emptyTokensText(QueueMsg)
                : tokens?.inProgress?.length === 0 &&
                  tokens?.inQueue?.length === 0 &&
                  emptyTokensText(QueueMsg)}

              {/* add this poind */}

              <div
                className={
                  view === "grid"
                    ? `row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-${getGridNum()} g-4 px-md-6 pt-1 mb-4`
                    : "row mb-4"
                }
              >
                {isLab ? (
                  tokens?.lab?.map((token) => (
                    <TokenCard
                      key={token?.tokenId}
                      token={token}
                      onLab={() => setLabToken(token)}
                      onEdit={() => setEditToken(token)}
                      onUpdateStatus={handleUpdateToken}
                      view={view}
                      FontSize={FontSize}
                      isToggeled={showEdit}
                    />
                  ))
                ) : (
                  <>
                    {tokens?.inProgress?.map((token) => (
                      <TokenCard
                        key={token?.tokenId}
                        token={token}
                        onLab={() => setLabToken(token)}
                        onEdit={() => setEditToken(token)}
                        onUpdateStatus={handleUpdateToken}
                        view={view}
                        FontSize={FontSize}
                        isToggeled={showEdit}
                      />
                    ))}
                    {tokens.inQueue.map((token) => (
                      <TokenCard
                        key={token?.tokenId}
                        token={token}
                        onLab={() => setLabToken(token)}
                        onEdit={() => setEditToken(token)}
                        onUpdateStatus={handleUpdateToken}
                        view={view}
                        FontSize={FontSize}
                        isToggeled={showEdit}
                      />
                    ))}
                  </>
                )}
              </div>

              {showEdit && (
                <>
                  <h3 className="text-dark m-3 fs-3 ">All Complete Booking</h3>
                  {/* <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-4 px-md-6 pt-1"> */}
                  {loader &&
                  tokens?.paymentPending?.length === 0 &&
                  tokens?.complete?.length === 0
                    ? emptyTokensText(CompleteMsg)
                    : tokens?.paymentPending?.length === 0 &&
                      tokens?.complete?.length == 0 &&
                      emptyTokensText(CompleteMsg)}
                  <div
                    className={
                      view === "grid"
                        ? `row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-${getGridNum()} g-4 px-md-6 pt-1 mb-4 `
                        : "row mb-4"
                    }
                  >
                    {tokens?.paymentPending?.map((token) => (
                      <TokenCard
                        key={token?.tokenId}
                        token={token}
                        onLab={() => setLabToken(token)}
                        onEdit={() => setEditToken(token)}
                        onUpdateStatus={handleUpdateToken}
                        view={view}
                        FontSize={FontSize}
                        isToggeled={showEdit}
                      />
                    ))}
                    {tokens?.complete?.map((token) => (
                      <TokenCard
                        key={token?.tokenId}
                        token={token}
                        onLab={() => setLabToken(token)}
                        onEdit={() => setEditToken(token)}
                        onUpdateStatus={handleUpdateToken}
                        view={view}
                        FontSize={FontSize}
                        isToggeled={showEdit}
                      />
                    ))}
                  </div>
                </>
              )}

              {editToken && (
                <TokenModal
                  token={editToken}
                  onUpdate={handleUpdateToken}
                  onClose={() => setEditToken(null)}
                />
              )}
              {labToken && (
                <ModalLabTest
                  recevedTokens={labToken}
                  onClose={() => setLabToken(null)}
                />
              )}
            </div>
            {screensSplit && (
              <div class="col">
                <AddContent
                  content={{
                    type: addData?.type,
                    src: addData?.url,
                    alt: addData?.title,
                    autoPlay: true,
                    muted: addData?.sounds,
                    loop: addData?.videoDuration == 0 ? true : false,
                  }}
                  // setIsVisible={true}
                  onClose={() => {
                    clearData();
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <ToastFist
          showToast={showToast}
          setShowToast={setShowToast}
          message={toastMsg}
          status={colorStatus}
        />
      </section>

      {addData && addData.position !== "screen-split" && (
        <Advertisement
          position={addData.position}
          size={addData.size}
          content={{
            type: addData.type,
            src: addData.url,
            alt: addData.title,
            autoPlay: true,
            muted: addData.sounds,
            loop: addData.videoDuration == 0 ? true : false,
          }}
          adDataclear={adDataclear}
          onClose={() => {
            setAddData();
            setScreenSplit(false);
          }}
        />
      )}
    </div>
  );
};

export default Booking;

