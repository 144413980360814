import { faEye, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UserReportRow = ({ report, url, index, handleReportAction }) => {
  const fileName = url?.split("/").pop() || "Report";
  const hasUrl = Boolean(url);


  const handleDownload = (action, report, url) => {
    if (!url) return;

    if (action === "view") {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      // Improved download logic
      const filename = url.split("/").pop();
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  };
  return (
    <tr key={`${report.tokenId}-${index}`}>
      <td className="fw-bold">#{report.tokenId}</td>
      <td>
        {new Date(report.date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </td>
      <td>{report.name}</td>
      <td className="text-end">
        <div className="d-flex gap-2 justify-content-end align-items-center">
          {/* <span className="text-muted me-2">{fileName}</span> */}
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={() => handleReportAction("view", report, url)}
            title="View Report"
            disabled={!hasUrl}
          >
            <FontAwesomeIcon icon={faEye} className="me-2" />
            Preview
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleDownload(url)}
            title="Download Report"
            disabled={!hasUrl}
          >
            <FontAwesomeIcon icon={faFileDownload} className="me-2" />
            Download
          </button>
        </div>
      </td>
    </tr>
  );
};
