import axios from "axios";
import swal from "sweetalert";
import {
  ACCESS_TOKEN,
  ADDDATAMASTERVISITPADS,
  BASE_URL,
  NO_DATA_ENTERED,
  SAVEROOTTEMPLATE,
  SESSION_ID,
  update_User_Token,
} from "../../Constant";
import { fetchTemplateNames } from "./FetchTemplateApi";

// Action Types
export const UPDATE_FIELD = "UPDATE_FIELD";
export const SUBMIT_DATA = "SUBMIT_DATA";
export const RESET_STATE = "RESET_STATE";

// Action Creators
export const updateField = (section, data) => ({
  type: UPDATE_FIELD,
  payload: { section, data },
});

export const resetState = () => ({
  type: RESET_STATE,
});

// const removeEmptyValues = (obj) => {
//   if (typeof obj !== "object" || obj === null) return obj;

//   return Object.fromEntries(
//     Object.entries(obj)
//       .filter(([_, v]) =>
//         v != null && // Exclude null and undefined values
//         v !== "" && // Exclude empty strings
//         !(typeof v === "object" && Object.keys(removeEmptyValues(v)).length === 0) // Exclude empty objects or objects with only empty values
//       )
//       .map(([k, v]) =>
//         [k, typeof v === "object" ? removeEmptyValues(v) : v] // Recursively clean nested objects
//       )
//   );
// };

export const removeEmptyValues = (obj) => {
  if (typeof obj !== "object" || obj === null) return obj;

  if (Array.isArray(obj)) {
    // Handle arrays separately
    return obj
      .map((item) => removeEmptyValues(item)) // Recursively clean array elements
      .filter(
        (item) =>
          item != null && // Exclude null and undefined values
          item !== "" && // Exclude empty strings
          !(typeof item === "object" && Object.keys(item).length === 0) // Exclude empty objects
      );
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    // Keep `labTestRecord` as-is even if it contains null values
    if (key === "labTestRecord") {
      acc[key] = value;
    } else {
      const cleanedValue = removeEmptyValues(value);
      // Add the cleaned value if it's not empty
      if (
        cleanedValue != null &&
        cleanedValue !== "" &&
        !(
          typeof cleanedValue === "object" &&
          Object.keys(cleanedValue).length === 0
        )
      ) {
        acc[key] = cleanedValue;
      }
    }
    return acc;
  }, {});
};

const section = localStorage.getItem(SESSION_ID);

// export const submitData = (data, receivedData) => async (dispatch) => {
//   const MAX_RETRIES = 3; // Limit the number of retries to 3
//   let attempt = 0;

//   // console.log(data.request.busiParams ,"data.request.busiParams")
//   const formatedBusiparam = removeEmptyValues(data.request.busiParams);
//   const isEmptyObject = (obj) => {
//     return Object.keys(obj).length === 0;
//   };

//   const ignoredFields = [
//     "customerName",
//     "date",
//     "phoneNo",
//     "tokenId",
//     "userId",
//     "customerId",
//   ];

//   // Function to remove the ignored fields from the object
//   const removeIgnoredFields = (obj, ignoredFields) => {
//     return Object.fromEntries(
//       Object.entries(obj).filter(([key]) => !ignoredFields.includes(key))
//     );
//   };
//   // console.log(formatedBusiparam ,"formatedBusiparam")

//   // Remove ignored fields from formatted busiParams before checking if it's empty
//   const cleanedBusiparam = removeIgnoredFields(
//     formatedBusiparam,
//     ignoredFields
//   );

//   if (isEmptyObject(cleanedBusiparam)) {
//     return NO_DATA_ENTERED;
//   }

//   const handleStatusChange = async () => {
//     try {
//       const requestPayload = {
//         pubInfo: {
//           sessionId: section ? section : "BDB90BAAF071E32E3C5125DD5451AFDE",
//         },
//         request: {
//           busiParams: {
//             ...receivedData,
//             status: receivedData?.status == 3 ? 3 : 4,
//             visitPad: true,
//             labTest:
//               formatedBusiparam?.labTestsAndImagingData?.length > 0 || false,
//             type: 0,
//           },
//           isEncrypt: false,
//           transactionId: "897987987989",
//         },
//       };
//       // console.log(requestPayload, "  dddd")
//       let res = await axios.post(
//         BASE_URL + update_User_Token,
//         JSON.stringify(requestPayload),
//         {
//           headers: {
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
//             "Access-Control-Allow-Headers": "Content-Type",
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
//           },
//         }
//       );
//       // console.log("success of api", res)
//       // console.log("success", res)
//     } catch (error) {
//       // alert("Failed to update status");
//     }
//   };

//   try {
//     const cleanedData = {
//       ...data,
//       request: {
//         ...data.request,
//         busiParams: formatedBusiparam,
//       },
//     };

//     // console.log( "sectionId--> ",  section)
//       console.log(cleanedData, " cleaned data---->> ");

//     const response = await axios.post(
//       BASE_URL + ADDDATAMASTERVISITPADS,
//       cleanedData,
//       {
//         headers: {
//           "Access-Control-Allow-Origin": "*",
//           "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
//           "Access-Control-Allow-Headers": "Content-Type",
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
//         },
//       }
//     );
//      console.log(response, "response@@@@@");

//     // console.log("success save", response);

//     // secondery approch

//    if (response?.data?.respData?.message === "Data is not saved") {
//      attempt++;
//      if (attempt < MAX_RETRIES) {
//        swal("Warning", `Attempt ${attempt} failed. Retrying...`, "warning");
//      } else {

//        return false;
//      }
//    } else {
//      handleStatusChange();
//      // swal("Success", "Data saved successfully", "success");
//      return true;
//    }

//   } catch

//   (error) {

// if (error.code === "ERR_NETWORK") {
//   console.error(
//     "Network Error: Please check your connection or server status."
//   );
// } else if (error.response) {
//   console.error("Server responded with an error:", error.response.data);
// } else if (error.request) {
//   console.error("No response received from the server:", error.request);
// } else {
//   console.error("Error:", error.message);
// }

//     return false;
//   }
// };

export const submitData = (data, receivedData) => async (dispatch) => {
  console.log(data, "  submit data ");
  const MAX_RETRIES = 3; // Limit the number of retries to 3
  let attempt = 0;

  const formatedBusiparam = removeEmptyValues(data.request.busiParams);

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  const ignoredFields = [
    "customerName",
    "date",
    "phoneNo",
    "tokenId",
    "userId",
    "customerId",
  ];

  const removeIgnoredFields = (obj, ignoredFields) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => !ignoredFields.includes(key))
    );

  const cleanedBusiparam = removeIgnoredFields(
    formatedBusiparam,
    ignoredFields
  );

  if (isEmptyObject(cleanedBusiparam)) {
    swal("Error", NO_DATA_ENTERED, "error");
    return false;
  }

  const handleStatusChange = async () => {
    try {
      const requestPayload = {
        pubInfo: {
          sessionId: section || "BDB90BAAF071E32E3C5125DD5451AFDE",
        },
        request: {
          busiParams: {
            ...receivedData,
            status: receivedData?.status === 3 ? 3 : 4,
            visitPad: true,
            labTest:
              formatedBusiparam?.labTestsAndImagingData?.length > 0 || false,
            type: 0,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      };

      const response = await axios.post(
        BASE_URL + update_User_Token,
        JSON.stringify(requestPayload),
        { headers: { "Content-Type": "application/json" } }
      );
    } catch (error) {
      console.log("Error", "Failed to update status", "error");
    }
  };

  while (attempt < MAX_RETRIES) {
    console.log("formatedBusiparam", formatedBusiparam);
    try {
      const cleanedData = {
        ...data,
        request: {
          ...data.request,
          busiParams: formatedBusiparam,
        },
      };

      console.log("cleanedData", cleanedData);

      const response = await axios.post(
        BASE_URL + ADDDATAMASTERVISITPADS,
        cleanedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          },
        }
      );

      console.log(response?.data?.respData?.message);

      if (response?.data?.respData?.message === "Data is not saved") {
        attempt++;
        if (attempt < MAX_RETRIES) {
          console.log(
            "Warning",
            `Attempt ${attempt} failed. Retrying...`,
            "warning"
          );
        } else {
          return false;
        }
      } else {
        handleStatusChange();
        // swal("Success", "Data saved successfully", "success");
        return true;
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        console.error(
          "Network Error: Please check your connection or server status."
        );

        alert("Network Error: Please check your connection or server status.");
      } else if (error.response) {
        console.error("Server responded with an error:", error.response.data);
      } else if (error.request) {
        console.error("No response received from the server:", error.request);
      } else {
        console.error("Error:", error.message);
      }

      return false;
    }
  }
};

export const TempletsubmitData = (data, templateName) => async (dispatch) => {
  try {
    // Destructure the data to remove the vitalsData section
    const { vitalsData, ...busiParamsWithoutVitals } = data.request.busiParams;

    // Construct the payload without vitalsData
    // console.log(data, 'ddd')
    const payload = {
      pubInfo: {
        sessionId: section ? section : "BDB90BAAF071E32E3C5125DD5451AFDE",
      },
      request: {
        busiParams: {
          ...busiParamsWithoutVitals,
          templateName: templateName, // Set the template name
        },
        isEncrypt: data.request.isEncrypt,
        transactionId: data.request.transactionId,
      },
    };
    // console.log("TempletsubmitData", payload);
    // Make the API call
    const response = await axios.post(
      `${BASE_URL}${SAVEROOTTEMPLATE}`,

      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
      }
    );

    // Handle success
    // swal("Success", "Templet submit Data saved successfully Add ");
    // // console.log("Data saved successfully", response.data);

    // Optionally dispatch an action to update the state
    // dispatch({ type: 'TEMPLATE_SUBMISSION_SUCCESS', payload: response.data });
    // dispatch(fetchTemplateNames());
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error during data submission:", error);
    // swal("Error", "Data not saved");
  }
};

// Initial State
const initialState = {
  pubInfo: {
    sessionId: section || "BDB90BAAF071E32E3C5125DD5451AFDE",
  },
  request: {
    busiParams: {
      customerId: "",
      customerName: "",
      date: "",
      phoneNo: null,
      tokenId: null,
      userId: null,
      vitalsData: {},
      complaintsData: {},
      historyData: {},
      generalExaminations: "",
      systemicExaminationData: {},
      diagnosisData: [],
      menstrualInfoData: {},
      obstetricHistoryData: {},
      obstetricAdditionalDatas: [],
      labTestsAndImagingData: {},
      physicalExamination: {},

      pastMedicationData: "",
      currentMedicationData: [],
      advice: "",
      testsRequestedData: {},
      nextVisit: "",
      investigationsData: [],
      referredTo: [],
      dietPlan: "",
    },
    isEncrypt: false,
    transactionId: "897987987989",
  },
};

// Reducer
export const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        request: {
          ...state.request,
          busiParams: {
            ...state.request.busiParams,
            [action.payload.section]: action.payload.data, // Overwrite the entire section with the new data
          },
        },
      };
    case RESET_STATE:
      return initialState; // Reset state to initial values
    default:
      return state;
  }
};
