// import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFilter,
//   faCalendarAlt,
//   faFilePrescription,
//   faFlask,
//   faListCheck,
//   faMicroscope,
//   faUser,
// } from "@fortawesome/free-solid-svg-icons";
// import apiHelper from "../ApiHelpar/apiHelper";
// import { SEARCH_TOKAN_USER } from "../visitConstant/ApiConstant";
// import moment from "moment";
// import { USER_INFO } from "../../Constant";

// const MyTokanHistory = () => {
//   // State management
//   const [activeFilter, setActiveFilter] = useState(0);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [filteredReports, setFilteredReports] = useState([]);

//   const PRESCRIPTION = 1;
//   const LAB = 2;
//   const ALL = 0;

//   // Dummy data

//   const GetDataToken = async () => {
//     const userData = JSON.parse(localStorage.getItem(USER_INFO));

//     console.log(userData);

//     try {
//       const data = {
//         startDate: startDate,
//         endDate: endDate,
//         mobileNo: userData.mobileNo,
//         pageSize: 10,
//         offset: 0,
//         flag: activeFilter,
//       };

//       console.log(data);
//       const res = await apiHelper(SEARCH_TOKAN_USER, data);
//       console.log(res);
//       const apiData = res.respData.respMsg.content;
//       setFilteredReports(apiData);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     GetDataToken();
//   }, [activeFilter, startDate, endDate]);

//   return (
//     <div className="container-lg py-4" style={{ marginTop: 120 }}>
//       {/* Header Section */}
//       <div className="bg-white rounded-3 p-3 mb-4 shadow-sm">
//         <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-3">
//           {/* Filter Title and Type Filters */}
//           <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-3">
//             <h3 className="h5 mb-0 d-flex align-items-center">
//               <FontAwesomeIcon icon={faFilter} className="text-primary me-2" />
//               Filter Reports
//             </h3>

//             {/* Type Filters as individual buttons */}
//             <div className="d-flex flex-wrap gap-2">
//               <button
//                 className={`btn btn-sm rounded-pill ${
//                   activeFilter === "all" ? "btn-primary" : "btn-outline-primary"
//                 } px-3`}
//                 onClick={() => setActiveFilter(ALL)}
//               >
//                 <FontAwesomeIcon icon={faListCheck} className="me-2" />
//                 All
//               </button>
//               <button
//                 className={`btn btn-sm rounded-pill ${
//                   activeFilter === "prescription"
//                     ? "btn-primary"
//                     : "btn-outline-primary"
//                 } px-3`}
//                 onClick={() => setActiveFilter(PRESCRIPTION)}
//               >
//                 <FontAwesomeIcon icon={faFilePrescription} className="me-2" />
//                 Prescriptions
//               </button>
//               <button
//                 className={`btn btn-sm rounded-pill ${
//                   activeFilter === "lab" ? "btn-primary" : "btn-outline-primary"
//                 } px-3`}
//                 onClick={() => setActiveFilter(LAB)}
//               >
//                 <FontAwesomeIcon icon={faFlask} className="me-2" />
//                 Lab Reports
//               </button>
//             </div>
//           </div>

//           {/* Date Filters */}
//           <div className="d-flex flex-column flex-sm-row gap-2">
//             <div className="input-group input-group-sm">
//               <span className="input-group-text bg-white border-end-0">
//                 <FontAwesomeIcon icon={faCalendarAlt} />
//               </span>
//               <input
//                 type="date"
//                 className="form-control rounded-end"
//                 value={startDate}
//                 onChange={(e) => setStartDate(e.target.value)}
//                 max={endDate}
//               />
//             </div>

//             <div className="input-group input-group-sm">
//               <span className="input-group-text bg-white border-end-0">
//                 <FontAwesomeIcon icon={faCalendarAlt} />
//               </span>
//               <input
//                 type="date"
//                 className="form-control rounded-end"
//                 value={endDate}
//                 onChange={(e) => setEndDate(e.target.value)}
//                 min={startDate}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Reports Grid */}
//       <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
//         {filteredReports.map((report) => (
//           <div className="col" key={report.id}>
//             <div className="card h-100 shadow-sm border-0">
//               {/* Card Header */}
//               <div className="card-header bg-white border-0 d-flex justify-content-between align-items-center p-3">
//                 <small className="text-muted fw-bold">#{report.tokenId}</small>
//                 <small className="text-muted">
//                   <FontAwesomeIcon icon={faCalendarAlt} className="me-2" />

//                   {moment(report.date).format("DD-MM-YYYY")}
//                 </small>
//               </div>

//               {/* Card Body */}
//               <div className="card-body d-flex flex-column">
//                 {/* Patient Info */}
//                 <div className="mb-4">
//                   <div className="d-flex align-items-center mb-3">
//                     <FontAwesomeIcon
//                       icon={faUser}
//                       className="text-primary me-2"
//                       style={{ width: "20px" }}
//                     />
//                     <h6 className="mb-0 fw-semibold">
//                       {report.customerId.name}
//                     </h6>
//                   </div>
//                   <div className="d-flex align-items-center">
//                     <FontAwesomeIcon
//                       icon="fa-solid fa-mobile-screen-button"
//                       className="text-primary me-2"
//                       style={{ width: "20px" }}
//                     />

//                     <small className="text-muted">
//                       Mobile No: {report.customerId.mobileNo}
//                     </small>
//                   </div>
//                 </div>

//                 {/* Card Text */}
//                 <p className="text-muted fs-7 mb-4 flex-grow-1">
//                   This report contains the results of your recent lab tests. You
//                   can view or download your prescription from the options below.
//                 </p>

//                 {/* Action Buttons */}
//                 <div className="d-grid gap-2 d-flex justify-content-end mt-auto">
//                   {report.visitPad && (
//                     <button
//                       className="btn btn-primary btn-sm d-flex align-items-center"
//                       style={{ minWidth: "120px" }}
//                     >
//                       <FontAwesomeIcon
//                         icon={faFilePrescription}
//                         className="me-2"
//                       />
//                       Prescription
//                     </button>
//                   )}

//                   {report?.labTest > 0 && (
//                     <button
//                       className="btn btn-outline-primary btn-sm d-flex align-items-center"
//                       style={{ minWidth: "120px" }}
//                     >
//                       <FontAwesomeIcon icon={faMicroscope} className="me-2" />
//                       Lab Report
//                     </button>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default MyTokanHistory;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faCalendarAlt,
  faFilePrescription,
  faFlask,
  faListCheck,
  faMicroscope,
  faUser,
  faTimes,
  faMobileScreenButton,
} from "@fortawesome/free-solid-svg-icons";
import apiHelper from "../ApiHelpar/apiHelper";
import { SEARCH_TOKAN_USER } from "../visitConstant/ApiConstant";
import moment from "moment";
import { ROLE, USER_INFO, USER_ROLE } from "../../Constant";
import { useNavigate } from "react-router-dom";
import { LabReportsModal } from "./LabReportsModal";

const MyTokanHistory = () => {
  // Constants for filter types
  const FILTER_TYPES = {
    ALL: 0,
    PRESCRIPTION: 1,
    LAB: 2,
  };

  // State management
  const [activeFilter, setActiveFilter] = useState(FILTER_TYPES.ALL);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredReports, setFilteredReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [showLabModal, setShowLabModal] = useState(false);
  const [currentLabReports, setCurrentLabReports] = useState([]);

  const navigate = useNavigate();

  // Format date for display
  const formatDateForDisplay = (date) => {
    return date ? moment(date).format("DD-MM-YYYY") : "";
  };

  // Fetch data from API
  const fetchTokenData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const userData = JSON.parse(localStorage.getItem(USER_INFO));
      const RoleAdmin = userData.role.includes(ROLE);
      const data = {
        startDate: startDate || null,
        endDate: endDate || null,
        mobileNo: RoleAdmin ? "" : userData?.mobileNo || "",
        pageSize: 10,
        offset: 0,
        flag: activeFilter,
      };

      const res = await apiHelper(SEARCH_TOKAN_USER, data);

      if (res?.respData?.respMsg?.content) {
        setFilteredReports(res.respData.respMsg.content);
      } else {
        setFilteredReports([]);
      }
    } catch (err) {
      console.error("Error fetching token data:", err);
      setError("Failed to fetch reports. Please try again.");
      setFilteredReports([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle filter change
  const handleFilterChange = (filterType) => {
    setActiveFilter(filterType);
  };

  // Clear all filters
  const clearAllFilters = () => {
    setActiveFilter(FILTER_TYPES.ALL);
    setStartDate("");
    setEndDate("");
  };

  // Check if any filter is active
  const isAnyFilterActive = () => {
    return activeFilter !== FILTER_TYPES.ALL || startDate || endDate;
  };

  // Effect to fetch data when filters change
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchTokenData();
    }, 300); // Debounce to avoid rapid API calls

    return () => clearTimeout(timer);
  }, [activeFilter, startDate, endDate]);



  const handleLabReportClick = (report) => {
    setCurrentLabReports(report); // Replace with your actual data
    setShowLabModal(true);
  };

  return (
    <div className="container-lg py-4" style={{ marginTop: "90px" }}>
      {/* Header Section */}
      <div className="bg-white rounded-3 p-3 mb-4 shadow-sm">
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between gap-3">
          {/* Filter Title and Type Filters */}
          <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-3">
            <h3 className="h5 mb-0 d-flex align-items-center">
              <FontAwesomeIcon icon={faFilter} className="text-primary me-2" />
              Filter Reports
            </h3>

            {/* Type Filters as individual buttons */}
            <div className="d-flex flex-wrap gap-2">
              <button
                className={`btn btn-sm rounded-pill ${
                  activeFilter === FILTER_TYPES.ALL
                    ? "btn-primary"
                    : "btn-outline-primary"
                } px-3`}
                onClick={() => handleFilterChange(FILTER_TYPES.ALL)}
              >
                <FontAwesomeIcon icon={faListCheck} className="me-2" />
                All
              </button>
              <button
                className={`btn btn-sm rounded-pill ${
                  activeFilter === FILTER_TYPES.PRESCRIPTION
                    ? "btn-primary"
                    : "btn-outline-primary"
                } px-3`}
                onClick={() => handleFilterChange(FILTER_TYPES.PRESCRIPTION)}
              >
                <FontAwesomeIcon icon={faFilePrescription} className="me-2" />
                Prescriptions
              </button>
              <button
                className={`btn btn-sm rounded-pill ${
                  activeFilter === FILTER_TYPES.LAB
                    ? "btn-primary"
                    : "btn-outline-primary"
                } px-3`}
                onClick={() => handleFilterChange(FILTER_TYPES.LAB)}
              >
                <FontAwesomeIcon icon={faFlask} className="me-2" />
                Lab Reports
              </button>

              {isAnyFilterActive() && (
                <button
                  className="btn btn-sm btn-outline-danger rounded-pill px-3"
                  onClick={clearAllFilters}
                >
                  <FontAwesomeIcon icon={faTimes} className="me-2" />
                  Clear All
                </button>
              )}
            </div>
          </div>

          {/* Date Filters and Clear Button */}
          <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
            <div className="input-group input-group-sm">
              <span className="input-group-text bg-white border-end-0">
                <FontAwesomeIcon icon={faCalendarAlt} />
              </span>
              <input
                type="date"
                className="form-control rounded-end"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={endDate || undefined}
              />
            </div>
            To

            <div className="input-group input-group-sm">
              <span className="input-group-text bg-white border-end-0">
                <FontAwesomeIcon icon={faCalendarAlt} />
              </span>
              <input
                type="date"
                className="form-control rounded-end"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate || undefined}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Loading and Error States */}
      {isLoading && (
        <div className="text-center py-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-2">Loading reports...</p>
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      {/* Reports Grid */}
      {!isLoading && !error && (
        <>
          {filteredReports.length > 0 ? (
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
              {filteredReports.map((report) => (
                <div className="col" key={report.id || report.tokenId}>
                  <div className="card h-100 shadow-sm border-0">
                    {/* Card Header */}
                    <div className="card-header bg-white border-0 d-flex justify-content-between align-items-center p-3">
                      <small className="text-muted fw-bold">
                        #{report.tokenId}
                      </small>
                      <small className="text-muted">
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="me-2"
                        />
                        {formatDateForDisplay(report.date)}
                      </small>
                    </div>

                    {/* Card Body */}
                    <div className="card-body d-flex flex-column">
                      {/* Patient Info */}
                      <div className="mb-4">
                        <div className="d-flex align-items-center mb-3">
                          <FontAwesomeIcon
                            icon={faUser}
                            className="text-primary me-2"
                            style={{ width: "20px" }}
                          />
                          <h6 className="mb-0 fw-semibold">
                            {report.customerId?.name || "N/A"}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faMobileScreenButton}
                            className="text-primary me-2"
                            style={{ width: "20px" }}
                          />
                          <small className="text-muted">
                            Mobile No: {report.customerId?.mobileNo || "N/A"}
                          </small>
                        </div>
                      </div>

                      {/* Card Text */}
                      <p className="text-muted fs-7 mb-4 flex-grow-1">
                        {activeFilter === FILTER_TYPES.PRESCRIPTION
                          ? "This prescription contains your doctor's recommendations and medications."
                          : activeFilter === FILTER_TYPES.LAB
                          ? "This report contains the results of your recent lab tests."
                          : "This record contains your visit details and related documents."}
                      </p>

                      {/* Action Buttons */}
                      <div className="d-grid gap-2 d-flex justify-content-end mt-auto">
                        {report.visitPad && (
                          <button
                            className="btn btn-primary btn-sm d-flex align-items-center"
                            style={{ minWidth: "120px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate("/Report", {
                                state: {
                                  LeftMenu: true,
                                  receivedData: report,
                                  selectedLanguages: "en",
                                  ageGender: {
                                    age: report?.age,
                                    gender: report?.gender,
                                  },
                                },
                              });
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faFilePrescription}
                              className="me-2"
                            />
                            Prescription
                          </button>
                        )}

                        {report?.labTestPaymentData && (
                          <button
                            className="btn btn-outline-primary btn-sm d-flex align-items-center"
                            style={{ minWidth: "120px" }}
                            onClick={() =>
                              handleLabReportClick(
                                report?.labTestPaymentData?.reportUrl
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={faMicroscope}
                              className="me-2"
                            />
                            Lab Report
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-5">
              <h5 className="text-muted">No reports found</h5>
              <p className="text-muted">
                Try adjusting your filters or date range
              </p>
              {isAnyFilterActive() && (
                <button
                  className="btn btn-outline-primary mt-3"
                  onClick={clearAllFilters}
                >
                  Clear all filters
                </button>
              )}
            </div>
          )}
        </>
      )}

      <LabReportsModal
        show={showLabModal}
        onHide={() => setShowLabModal(false)}
        labReports={currentLabReports}
      />
    </div>
  );
};

export default MyTokanHistory;
