import { useState, useEffect } from "react";
import apiHelper from "./apiHelper";
import { DataConvertecheck } from "../DataConverte/DataConverte";
import useDebounce from "../useDebounce/useDebounce";
import { DROPDOWNSARCHYNAME } from "../visitConstant/ApiConstant";
import getApiHelper from "./getApiHelper";

const DropdownSearchApl = (
  endpoint = DROPDOWNSARCHYNAME,
  searchInput,
  categoryId,
  delay = 100
) => {
  const [DropdownServerData, setServerData] = useState("");
  const [DropdownIsLoading, setIsLoading] = useState(false);
  const [DropdownError, setError] = useState(null);

  const debouncedInput = useDebounce(searchInput, delay);

  useEffect(() => {
    const fetchData = async () => {
      if (!debouncedInput || debouncedInput.length < 1) return;

      setIsLoading(true); // Show loader
      setError(null); // Reset error before making a new request

      try {
        const data = {
          catId: categoryId,
          prefix: searchInput,
        };

        console.log(categoryId, debouncedInput, "API colling 01");
        const response = await getApiHelper(endpoint, data);
        // console.log(response, "response --->>> response")
        const dataConvert = DataConvertecheck(response?.respData?.respMsg);
        //  console.log(dataConvert , "API colling 02")
        setServerData(dataConvert);
      } catch (err) {
        console.log("Error fetching data:", err);
        setError(err);
      } finally {
        setIsLoading(false); // Hide loader
      }
    };

    fetchData();
  }, [debouncedInput, endpoint, categoryId]);

  return { DropdownServerData, DropdownIsLoading, DropdownError };
};

export default DropdownSearchApl;

// const { serverData, isLoading, error } = useFetchData(
//     DROPDOWNSARCHYNAME,
//     inputValue,
//     Categories?.COMPLAINTS.catID
//   );
