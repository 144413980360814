import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import jivdanirepot_header from "../Images/jivdanirepot_header.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import dummyHistData from "./Utils/visitPadHistory.json";
import {
  ACCESS_TOKEN,
  BASE_URL,
  GETALLMASTERVISITPADSBUTD,
  USER_INFO,
} from "../Constant";
import { useSelector } from "react-redux";
import { Color } from "./visitConstant/Color";

const PrintDivComponent = () => {
  const location = useLocation();

  const {
    receivedData,
    selectedLanguages,
    ageGender,
    LeftMenu = false,
  } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [crMedicine, setCrMedicine] = useState({});
  // console.log(data, " receved");
  const currentDate = moment().format("YYYY-MM-DD");
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [clickedTime, setClickedTime] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
    // Function to fetch data
    let currentTime = moment().format("LT");
    setClickedTime(currentTime);
    const fetchData = async () => {
      setLoading(true); // Start loading
      setError(false); // Clear previous errors

      try {
        const response = await axios.post(
          BASE_URL + GETALLMASTERVISITPADSBUTD,
          {
            pubInfo: {
              sessionId: "3762D32E2F01170A2D5B50B5C74F48C7",
            },
            request: {
              busiParams: {
                tokenId: receivedData?.tokenId,
              },
              isEncrypt: false,
              transactionId: "897987987989",
            },
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
            },
          }
        );
        // console.log(response.data.respData, "report data");
        const reportdata = response.data.respData.respMsg[0];
        const sortedmedcinine = reportdata?.currentMedicationData?.sort(
          (a, b) => a.currentMedicationDataId - b.currentMedicationDataId
        );
        setCrMedicine(sortedmedcinine);
        // console.log("err ", err)
        // console.log("sorted", sortedmedcinine);
        if (response.data.respData.respMsg == "done") {
          setData(undefined);
        } else {
          setData(reportdata);
        }

        setLoading(false);
        setError(false);
      } catch (err) {
        // console.log("errreport ", err)
        setError(true);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  let ageGenderInfo = "";

  if (ageGender) {
    const age = ageGender.age ? `${ageGender.age} Y` : "";
    const gender = ageGender.gender
      ? ageGender?.gender == "M"
        ? "Male"
        : ageGender?.gender == "F"
        ? "Female"
        : "Other"
      : "";

    // Create the parentheses content based on availability
    if (age && gender) {
      ageGenderInfo = `(${age}, ${gender})`;
    } else if (age) {
      ageGenderInfo = `(${age})`;
    } else if (gender) {
      ageGenderInfo = `(${gender})`;
    }
  }

  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
     @page {
      size: A4;
      margin-bottom: 130px;
      margin-top:180px;
    }

    @media print {
      body {
        font-family: 'Arial', sans-serif;
        margin: 0;
        padding: 0;
      }

      // .print-header {
      //   position: fixed;
      //   top: 0;
      //   width: 100%;
      //   // height: 160px;
      //   // background-color: #f0f0f0;
      //   border-bottom: 1px solid black;
      //   padding-bottom: 10px;
      // }

      // .print-footer {
      //   position: fixed;
      //   bottom: 0;
      //   width: 100%;
      //   // height: 100px;
      //   // background-color: #f0f0f0;
      //   border-top: 1px solid black;
      //   padding-top: 10px;
      // }

      .print-content {
        /* Account for header and footer space */
        // margin-top: 180px; /* Space below header */
        page-break-inside: auto; /* Avoid breaking inside elements */
      }

      // .print-content::after {
      //   content: '';
      //   display: block;
      //   margin-bottom: 120px; /* Spacer to ensure content doesn't go behind the footer */
      // }
    }
    `,
  });

  const [dropdownData, setdropdownData] = useState([]);
  const rowsStyle = { fontSize: 15, margin: 0, paddingLeft: 7 };
  // Function to handle language conversion
  const convertToLanguage = (text, language) => {
    if (!text) return ""; // Handle undefined or null text
    // Handle conversion for text based on your existing data
    if (dropdownData[text]) {
      // console.log(dropdownData[text][language], "========>>>>>>>>");
      return dropdownData[text][language];
    }

    return text; // Fallback to original text if translation is not found
  };

  const convertToLanguageV2 = (text, language) => {
    let lowertext = text?.toLowerCase();
    let result = lowertext?.trim();
    let newText = result?.replace(/\s+/g, "_");

    if (!text) return ""; // Handle undefined or null text
    // Handle conversion for text based on your existing data
    if (dropdownData[newText]) {
      return dropdownData[newText][language];
    }

    return text; // Fallback to original text if translation is not found
  };

  // Handle language selection change
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const API_URL_Language =
    "https://jivdanihospital.com/jivdani-v1/downloadFileFromFolder/11/data.json";

  // Fetch data from API

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL_Language, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      });

      const data = response.data;
      setdropdownData(data);
      // console.log("data======>>>>>>>>==>>", data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  const subHeading = {
    paddingRight: 8,
    fontSize: 15,
    fontWeight: "bold",
    margin: 0,
  };
  const horizontalRow = {
    display: "flex",
    flexWrap: "wrap",
    listStyleType: "none",
    padding: 0,
    margin: 0,
  };
  // console.log("ata?.referredTo?.length", data?.referredTo?.length)
  // Effect hook to fetch data on component mount
  useEffect(() => {
    // if(selectedLanguages){
    setSelectedLanguage(selectedLanguages);
    // }
    //  alert(selectedLanguages)
    // alert(selectedLanguages)
    fetchData(); // Fetch data on initial render
  }, []);

  const datCheck = ["sos", "Bed Time", "Empty Stomach"];

  return (
    <div className="container">
      <div className="  mb-4" style={{ marginTop: 120, overflow: "hidden" }}>
        <div class="row " style={{ justifyContent: "center" }}>
          {/* <div class="col-8 "> */}
          <div className={` card col-xl-8 col-12`}>
            <div ref={componentRef}>
              {/* header content */}
              <div
                class=" position-fixed top-0"
                style={{
                  width: "100%",
                  marginRight: 25,
                  marginLeft: 20,
                }}
              ></div>
              <div className="print-content">
                {loading ? (
                  <div
                    className="d-flex justify-content-center  "
                    style={{ alignItems: "center", height: "100vh" }}
                  >
                    <div
                      className="spinner-border text-primary fs-5"
                      role="status"
                      style={{ alignSelf: "center", height: 50, width: 50 }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : data != undefined ? (
                  <div
                    className="m-md-5 m-2"
                    // style={{ marginLeft: 50, marginRight: 50, marginTop: 40 }}
                  >
                    <div
                      class="border-bottom border-black"
                      style={{ paddingBottom: 4 }}
                    >
                      <div>
                        <div className="d-flex">
                          <p
                            className="col-2 fw-medium"
                            style={{ margin: 0, paddingRight: 8 }}
                          >
                            {data?.tokenId}{" "}
                          </p>
                          <p style={{ margin: 0 }}>
                            <strong className="fw-medium">
                              :{" "}
                              {receivedData?.customerId?.salutation &&
                                receivedData?.customerId?.salutation}{" "}
                              {data?.customerName} {ageGenderInfo}
                            </strong>{" "}
                            {data?.phoneNo != 0 && "- " + data?.phoneNo}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p
                            className="col-2 fw-medium"
                            style={{ margin: 0, paddingRight: 8 }}
                          >
                            Date & Time{" "}
                          </p>
                          <p style={{ margin: 0 }}>
                            <strong className="fw-medium">
                              : {moment(data?.date).format("DD-MMM-YYYY")}{" "}
                              {clickedTime}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div style={{ paddingBottom: 4, paddingTop: 5 }}>
                      <div>
                        {data?.historyData?.allergies?.length > 0 && (
                          <div className="fs-6" style={{ fontSize: 15 }}>
                            <p style={{ margin: 0 }}>
                              <span
                                style={{
                                  paddingRight: 8,
                                  fontWeight: "bold",
                                  margin: 0,
                                  fontStyle: "italic",
                                  fontSize: 15,
                                }}
                              >
                                Allergies :{" "}
                              </span>
                              <span className="">
                                {data?.historyData?.allergies
                                  ?.map((hist) => hist?.name)
                                  .join(", ")}
                              </span>
                            </p>
                          </div>
                        )}
                        {data?.historyData?.familyHistory?.length > 0 && (
                          <div className="fs-6" style={{ fontSize: 15 }}>
                            <p style={{ margin: 0 }}>
                              <span
                                style={{
                                  paddingRight: 8,
                                  fontWeight: "bold",
                                  margin: 0,
                                  fontStyle: "italic",
                                  fontSize: 15,
                                }}
                              >
                                Family History :{" "}
                              </span>
                              <span>
                                {data?.historyData?.familyHistory
                                  ?.map((hist) => hist?.name)
                                  .join(",  ")}
                              </span>
                            </p>
                          </div>
                        )}
                        {data?.historyData?.personalHistory?.length > 0 && (
                          <div className="fs-6" style={{ fontSize: 15 }}>
                            <p style={{ margin: 0 }}>
                              <span
                                style={{
                                  paddingRight: 8,
                                  fontWeight: "bold",
                                  margin: 0,
                                  fontStyle: "italic",
                                  fontSize: 15,
                                }}
                              >
                                Personal History :{" "}
                              </span>
                              <span>
                                {data?.historyData?.personalHistory
                                  ?.map((hist) => hist?.name)
                                  .join(", ")}
                              </span>
                            </p>
                          </div>
                        )}
                        {data?.historyData?.pastMedicalhistory?.length > 0 && (
                          <div className="fs-6" style={{ fontSize: 15 }}>
                            <p style={{ margin: 0 }}>
                              <span
                                style={{
                                  paddingRight: 8,
                                  fontWeight: "bold",
                                  margin: 0,
                                  fontStyle: "italic",
                                  fontSize: 15,
                                }}
                              >
                                Past Medical History :{" "}
                              </span>
                              <span>
                                {data?.historyData?.pastMedicalhistory
                                  ?.map((hist) => hist?.name)
                                  .join(", ")}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                      {data?.vitalsData && (
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                              fontSize: 15,
                            }}
                          >
                            Vitals:
                          </p>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <p style={{ fontSize: 15, margin: 0 }}>
                              <strong className="fw-medium">BP:</strong>{" "}
                              {data?.vitalsData?.bloodPressure} mmhg
                            </p>

                            {data?.vitalsData?.pulse !== 0 && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">Pulse:</strong>{" "}
                                {data?.vitalsData?.pulse} bpm
                              </p>
                            )}
                            {data?.vitalsData?.height !== 0 && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">Height:</strong>{" "}
                                {data?.vitalsData?.height} cm
                              </p>
                            )}
                            {data?.vitalsData?.weight !== 0 && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">Weight:</strong>{" "}
                                {data?.vitalsData?.weight} kg
                              </p>
                            )}

                            {data?.vitalsData?.temperature !== 0 && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">Temp:</strong>{" "}
                                {data?.vitalsData?.temperature} F
                              </p>
                            )}
                            {data?.vitalsData?.bmi !== 0 && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">Bmi:</strong>{" "}
                                {data?.vitalsData?.bmi} kg/m2
                              </p>
                            )}
                            {data?.vitalsData?.waistHip !== 0 && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Waist/Hip:
                                </strong>{" "}
                                {data?.vitalsData?.waistHip}{" "}
                              </p>
                            )}
                            {data?.vitalsData?.spo2 !== 0 && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">SPO2:</strong>{" "}
                                {data?.vitalsData?.spo2} %
                              </p>
                            )}
                            {data?.vitalsData?.fetalHeartSound && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">FHS:</strong>{" "}
                                {data?.vitalsData?.fetalHeartSound}{" "}
                              </p>
                            )}
                            {data?.vitalsData?.fetalMovement && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">FM:</strong>{" "}
                                {data?.vitalsData?.fetalMovement}{" "}
                              </p>
                            )}
                            {data?.vitalsData?.hgt !== 0 && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">HGT:</strong>{" "}
                                {data?.vitalsData?.hgt}{" "}
                              </p>
                            )}
                            {data?.vitalsData?.lastMenstrualPeriod && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">LMP:</strong>{" "}
                                {moment(
                                  data?.vitalsData?.lastMenstrualPeriod
                                ).format("DD-MMM-YYYY")}{" "}
                              </p>
                            )}
                            {data?.vitalsData?.estimatedDueDate && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">EDD:</strong>{" "}
                                {moment(
                                  data?.vitalsData?.estimatedDueDate
                                ).format("DD-MMM-YYYY")}{" "}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      {data?.investigationsData?.length > 0 && (
                        <>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            Investigation:{" "}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              fontSize: 15,
                              flexDirection: "column",
                            }}
                          >
                            {data?.investigationsData[0]?.investigationsDates?.map(
                              (dateItem, idx) => {
                                // Get the date from the first investigationsData entry and format it
                                const formattedDate = moment(
                                  dateItem?.investigationsDate
                                ).format("DD-MMM-YYYY");

                                // For each date, find the corresponding test types and values
                                const dateGroup = data?.investigationsData
                                  .map((testItem) => {
                                    // Find the matching entry for the current date
                                    const matchingDate =
                                      testItem.investigationsDates.find(
                                        (d) =>
                                          moment(d.investigationsDate).format(
                                            "DD-MMM-YYYY"
                                          ) === formattedDate
                                      );

                                    // Return the test type and value if found
                                    if (matchingDate) {
                                      return `${
                                        testItem.testsOrInvestigations?.name
                                      }: ${
                                        matchingDate.value
                                          ? matchingDate.value
                                          : ""
                                      }`;
                                    }
                                    return null;
                                  })
                                  .filter(Boolean); // Filter out any null/undefined values

                                // Render the date along with the test types and values
                                return (
                                  <p key={idx} style={{ fontWeight: "bold" }}>
                                    [{formattedDate}] {dateGroup.join(", ")}
                                  </p>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}

                      {data?.complaintsData?.complaints?.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            paddingTop: 3,
                            paddingBottom: 3,
                            fontSize: 15,
                          }}
                        >
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            Complaints:{" "}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {data?.complaintsData?.complaints.map(
                              (complaint, idx, arr) => (
                                <li
                                  style={{ paddingRight: 10 }}
                                  key={complaint?.id}
                                >
                                  {complaint?.name}{" "}
                                  {idx < arr.length - 1 && ", "}
                                </li>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      {data?.generalExaminations && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: 3,
                            paddingBottom: 3,
                            fontSize: 15,
                          }}
                        >
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            General Examination:
                          </p>
                          {/* <div style={{ display: 'flex', flexWrap: 'wrap', listStyleType: 'none', padding: 0, margin: 0,  alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}> */}
                          <p style={{ margin: 0 }}>
                            {data?.generalExaminations}
                          </p>
                          {/* </div> */}
                        </div>
                      )}
                      {data?.diagnosisData?.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            paddingTop: 3,
                            paddingBottom: 3,
                            fontSize: 15,
                          }}
                        >
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            Diagnosis:
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {data?.diagnosisData?.map((item, idx, arr) => (
                              <li
                                style={{
                                  paddingRight: 10,
                                  textDecoration: "underline",
                                  borderBottomColor: "black",
                                }}
                                key={item?.diagnosis?.id}
                              >
                                {item?.diagnosis?.name}{" "}
                                <span className="fw-semibold">
                                  {item?.duration &&
                                    `since ${
                                      item?.duration ? item?.duration : ""
                                    }`}
                                </span>{" "}
                                {idx < arr.length - 1 && ", "}
                              </li>
                            ))}
                          </div>
                        </div>
                      )}
                      <div style={{ display: "flex", fontSize: 15 }}>
                        {data?.systemicExaminationData && (
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Systemic Examination:
                          </p>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            fontSize: 15,
                          }}
                        >
                          {data?.systemicExaminationData?.cns?.length > 0 && (
                            <div style={{ display: "flex" }}>
                              <p style={subHeading} className="fw-medium">
                                CNS:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.cns?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{ paddingRight: 10, fontSize: 15 }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.cvs?.length > 0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p style={subHeading} className="fw-medium">
                                CVS:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.cvs?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{ paddingRight: 10, fontSize: 15 }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.ent?.length > 0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p style={subHeading} className="fw-medium">
                                ENT:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.ent?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{ paddingRight: 10, fontSize: 15 }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.generals?.length >
                            0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p style={subHeading} className="fw-medium">
                                GENERALS:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.generals?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{ paddingRight: 10, fontSize: 15 }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.pa?.length > 0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p style={subHeading} className="fw-medium">
                                PA:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.pa?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{ paddingRight: 10, fontSize: 15 }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {data?.systemicExaminationData?.rs?.length > 0 && (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              <p style={subHeading} className="fw-medium">
                                RS:
                              </p>
                              <div style={horizontalRow}>
                                {data?.systemicExaminationData?.rs?.map(
                                  (Syst, idx, arr) => (
                                    <li
                                      style={{ paddingRight: 10, fontSize: 15 }}
                                      key={Syst?.id}
                                    >
                                      {Syst?.name}
                                      {idx < arr.length - 1 && ", "}
                                    </li>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Medicine Information     */}

                    {crMedicine?.length >= 1 && (
                      <>
                        <FontAwesomeIcon icon="fa-solid fa-prescription" />
                        <table
                          className="table me-5"
                          style={{
                            borderCollapse: "collapse",
                            marginTop: 8,
                            borderColor: "black",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  fontWeight: "bold",
                                  padding: "2px 0",
                                  fontSize: 15,
                                }}
                              ></th>
                              <th
                                scope="col"
                                style={{
                                  fontWeight: "bold",
                                  padding: "2px 0",
                                  fontSize: 15,
                                  width: 200,
                                }}
                              >
                                Medicine
                              </th>
                              <th
                                scope="col"
                                style={{
                                  fontWeight: "bold",
                                  padding: "2px 0 0 7px",
                                  fontSize: 15,
                                }}
                              >
                                <div className="d-flex">
                                  <p className="col-3 m-0"> Dosage</p>
                                  <p className="m-0">Freq. - Duration</p>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {crMedicine?.map((item, index) => (
                              <React.Fragment
                                key={item?.currentMedicationDataId}
                              >
                                <tr>
                                  {/* Medicine Number */}
                                  <td
                                    scope="row"
                                    className="fw-normal"
                                    rowSpan={
                                      item?.medicationScheduleList?.length + 1
                                    }
                                  >
                                    {index + 1 + ")"}
                                  </td>

                                  {/* Medicine Name */}
                                  <td
                                    style={{ fontWeight: "bold", fontSize: 15 }}
                                    rowSpan={
                                      item?.medicationScheduleList?.length + 1
                                    }
                                  >
                                    {item?.medicineId?.name}
                                    {item?.medicineId?.contents && (
                                      <div
                                        style={{
                                          fontSize: 14,
                                          fontWeight: "normal",
                                        }}
                                      >
                                        <span style={{ fontStyle: "italic" }}>
                                          Composition:{" "}
                                        </span>
                                        {convertToLanguage(
                                          item?.medicineId?.contents,
                                          selectedLanguage
                                        )}
                                      </div>
                                    )}
                                  </td>

                                  {/* Dosage & Frequency - Duration */}
                                </tr>

                                {/* Timing and Notes */}
                                {item?.medicationScheduleList?.map(
                                  (subItem, subIndex) => (
                                    <tr key={subIndex}>
                                      <td>
                                        <div
                                          className="d-flex fw-medium "
                                          key={subIndex}
                                        >
                                          <span className="col-3">
                                            {convertToLanguageV2(
                                              subItem?.dosages?.name,
                                              selectedLanguage
                                            )}{" "}
                                            {item?.unit?.name
                                              ? ` (${item.unit.name})`
                                              : ""}
                                          </span>
                                          <span className="">
                                            {convertToLanguageV2(
                                              subItem?.frequency?.name,
                                              selectedLanguage
                                            )}{" "}
                                            {subItem?.duration &&
                                              `- ${
                                                subItem?.duration?.split(" ")[0]
                                              }`}{" "}
                                            {convertToLanguageV2(
                                              subItem?.duration?.split(" ")[1],
                                              selectedLanguage
                                            )}
                                          </span>
                                        </div>
                                        {/* Timing */}
                                        {datCheck.includes(
                                          subItem?.whens?.name
                                        ) || !subItem?.whens?.name
                                          ? subItem?.dosages?.name && (
                                              <div
                                                className="d-flex"
                                                style={{ fontSize: 14 }}
                                              >
                                                <span
                                                  className="col-3"
                                                  style={{
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  Timing:
                                                </span>
                                                <div className="">
                                                  {subItem?.dosages?.name
                                                    ?.split("-")
                                                    ?.map(
                                                      (dos, idx, arr) =>
                                                        dos != 0 && (
                                                          <span key={idx}>
                                                            {dos}{" "}
                                                            {item?.unit?.name
                                                              ? ` (${item.unit.name})`
                                                              : ""}{" "}
                                                            {convertToLanguageV2(
                                                              `${
                                                                idx === 0
                                                                  ? "Morning"
                                                                  : idx === 1
                                                                  ? "Afternoon"
                                                                  : "Night"
                                                              } ${
                                                                subItem?.whens
                                                                  ?.name
                                                                  ? subItem
                                                                      ?.whens
                                                                      ?.name
                                                                  : ""
                                                              }`,
                                                              selectedLanguage
                                                            )}
                                                            {idx <
                                                              arr.length - 1 &&
                                                              ", "}
                                                          </span>
                                                        )
                                                    )}
                                                </div>
                                              </div>
                                            )
                                          : subItem?.dosages?.name && (
                                              <div
                                                className="d-flex"
                                                style={{ fontSize: 14 }}
                                              >
                                                <span
                                                  className="col-3"
                                                  style={{
                                                    fontStyle: "italic",
                                                  }}
                                                >
                                                  Timing:
                                                </span>
                                                <div className="">
                                                  {subItem?.dosages?.name
                                                    ?.split("-")
                                                    ?.map(
                                                      (dos, idx, arr) =>
                                                        dos != 0 && (
                                                          <span key={idx}>
                                                            {dos}{" "}
                                                            {item?.unit?.name
                                                              ? ` (${item.unit.name})`
                                                              : ""}{" "}
                                                            {convertToLanguageV2(
                                                              ` ${
                                                                subItem?.whens
                                                                  ?.name
                                                                  ? subItem?.whens?.name?.split(
                                                                      " "
                                                                    )[0]
                                                                  : " "
                                                              } ${
                                                                idx == 0
                                                                  ? "Breakfast"
                                                                  : idx == 1
                                                                  ? "Lunch"
                                                                  : "Dinner"
                                                              }`,
                                                              selectedLanguage
                                                            )}
                                                            {idx <
                                                              arr.length - 1 &&
                                                              ", "}
                                                          </span>
                                                        )
                                                    )}
                                                </div>
                                              </div>
                                            )}

                                        {/* Notes */}
                                        {subItem?.notes && (
                                          <div
                                            className="d-flex"
                                            style={{
                                              fontSize: 14,
                                              marginTop: 4,
                                            }}
                                          >
                                            <span
                                              className="col-3"
                                              style={{ fontStyle: "italic" }}
                                            >
                                              Notes:
                                            </span>
                                            {convertToLanguage(
                                              subItem?.notes,
                                              selectedLanguage
                                            )}
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}

                    <div style={{ paddingTop: 7 }}>
                      {data?.dietPlan && (
                        <div style={{ display: "flex", fontSize: 15 }}>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            Diet Plan:
                          </p>
                          <p style={{ margin: 0 }}>{data?.dietPlan}</p>
                        </div>
                      )}

                      {data?.advice && (
                        <div style={{ display: "flex", fontSize: 15 }}>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            Advice:
                          </p>
                          <p style={{ margin: 0 }}>{data?.advice}</p>
                        </div>
                      )}
                      {data?.testsRequestedData?.testsRequesteds?.length >
                        0 && (
                        <div style={{ display: "flex", fontSize: 15 }}>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            Test Prescribed:
                          </p>
                          {data?.testsRequestedData?.whenBy && (
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "bold",
                                marginRight: 10,
                              }}
                            >
                              {data?.testsRequestedData?.whenBy !=
                              " - undefined"
                                ? `[${data?.testsRequestedData?.whenBy}]`
                                : ""}
                            </p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {data?.testsRequestedData?.testsRequesteds?.map(
                              (text, idx, arr) => (
                                <li style={{ marginRight: 10 }} key={text?.id}>
                                  {text?.name}
                                  {idx < arr.length - 1 && ", "}
                                </li>
                              )
                            )}
                          </div>
                        </div>
                      )}
                      {data?.labTestsAndImagingData?.length > 0 && (
                        <div style={{ display: "flex", fontSize: 15 }}>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            Lab Tests and Imaging:
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {data?.labTestsAndImagingData
                              ?.sort(
                                (a, b) =>
                                  a.labTestsAndImagingDataID -
                                  b.labTestsAndImagingDataID
                              )
                              ?.map((text, index) => (
                                <li style={{ marginRight: 10 }} key={text?.id}>
                                  {text?.labTestsAndImaging?.name}{" "}
                                  {index <
                                    data.labTestsAndImagingData.length - 1 &&
                                    ", "}
                                </li>
                              ))}
                          </div>
                        </div>
                      )}

                      {data?.menstrualInfoData && (
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                              fontSize: 15,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Menstrual Info:
                          </p>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {data?.menstrualInfoData?.lmp && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">LMP:</strong>{" "}
                                {moment(data?.menstrualInfoData?.lmp).format(
                                  "DD-MMM-YYYY"
                                )}{" "}
                              </p>
                            )}
                            {data?.menstrualInfoData?.ageAtMenarche && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Age at Menarche:
                                </strong>{" "}
                                {data?.menstrualInfoData?.ageAtMenarche}
                              </p>
                            )}
                            {data?.menstrualInfoData?.regularityOfMenstrualCycle
                              ?.name && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Regularity of Menstrual Cycle:
                                </strong>{" "}
                                {
                                  data?.menstrualInfoData
                                    ?.regularityOfMenstrualCycle?.name
                                }
                              </p>
                            )}

                            {data?.menstrualInfoData
                              ?.durationForMenstrualBleeding && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Duration for Menstrual Bleeding:
                                </strong>{" "}
                                {
                                  data?.menstrualInfoData
                                    ?.durationForMenstrualBleeding
                                }
                              </p>
                            )}
                            {data?.menstrualInfoData
                              ?.periodOfMenstrualCycle && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Period of Menstrual Cycle:
                                </strong>{" "}
                                {
                                  data?.menstrualInfoData
                                    ?.periodOfMenstrualCycle
                                }{" "}
                              </p>
                            )}
                            {data?.menstrualInfoData
                              ?.detailsOfMenstrualIssues && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Details of Menstrual Issues:
                                </strong>{" "}
                                {
                                  data?.menstrualInfoData
                                    ?.detailsOfMenstrualIssues
                                }{" "}
                              </p>
                            )}
                            {data?.menstrualInfoData?.note && (
                              <p style={rowsStyle}>
                                | <strong className="fw-medium">Notes:</strong>{" "}
                                {data?.menstrualInfoData?.note}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      {(data?.physicalExamination?.breastExamination ||
                        data?.physicalExamination?.perSpeculum ||
                        data?.physicalExamination?.perAbdominalExamination ||
                        data?.physicalExamination?.perVaginalExamination) && (
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                              fontSize: 15,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Physical Examination:
                          </p>

                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {data?.physicalExamination?.breastExamination && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Breast Examination:
                                </strong>{" "}
                                {data?.physicalExamination?.breastExamination}
                              </p>
                            )}
                            {data?.physicalExamination?.perSpeculum && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Per Speculum:
                                </strong>{" "}
                                {data?.physicalExamination?.perSpeculum}
                              </p>
                            )}
                            {data?.physicalExamination
                              ?.perAbdominalExamination && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Abdominal Examination:
                                </strong>{" "}
                                {
                                  data?.physicalExamination
                                    ?.perAbdominalExamination
                                }
                              </p>
                            )}
                            {data?.physicalExamination
                              ?.perVaginalExamination && (
                              <p style={rowsStyle}>
                                |{" "}
                                <strong className="fw-medium">
                                  Vaginal Examination:
                                </strong>{" "}
                                {
                                  data?.physicalExamination
                                    ?.perVaginalExamination
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      {data?.nextVisit && (
                        <div style={{ display: "flex", fontSize: 15 }}>
                          <p
                            style={{
                              paddingRight: 8,
                              fontWeight: "bold",
                              margin: 0,
                              fontStyle: "italic",
                            }}
                          >
                            Next Visit:
                          </p>
                          <p style={{ margin: 0 }}>
                            {currentDate == data?.date &&
                              Math.ceil(
                                moment(data?.nextVisit).diff(
                                  moment(),
                                  "hours"
                                ) / 24
                              ) !== 0 &&
                              `In ${Math.ceil(
                                moment(data?.nextVisit).diff(
                                  moment(),
                                  "hours"
                                ) / 24
                              )} days`}{" "}
                            (
                            {moment(data?.nextVisit).format(
                              "DD-MMM-YYYY - dddd"
                            )}
                            )
                          </p>
                        </div>
                      )}
                      {data?.referredTo?.length > 0 && (
                        <div style={{ display: "flex", fontSize: 15 }}>
                          <p
                            style={{
                              marginRight: "10px",
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }}
                          >
                            ReferredTo:
                          </p>
                          <div
                            style={{
                              flexWrap: "wrap",
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {data?.referredTo?.map((text, index) => (
                              <li style={{}} key={text?.referredToId}>
                                <strong>{index + 1 + ") "}</strong>
                                {text?.doctorId?.name}{" "}
                                {text?.speciality
                                  ? "(" + text?.specialityId?.name + ")"
                                  : null}{" "}
                                {text?.doctorId?.mobileNo
                                  ? "Ph: " + text?.doctorId?.mobileNo
                                  : null}
                              </li>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        width: "100%",
                        marginBottom: 15,
                        marginTop: 20,
                        fontSize: 15,
                        alignItems: "end",
                      }}
                    >
                      <p
                        className="text-end"
                        style={{ marginBottom: 0, fontWeight: "bold" }}
                      >
                        {/* {profiledata?.name ? profiledata?.name : "Dr Somnath Dares"} */}
                        Dr Somnath Dare
                      </p>
                      <p
                        className="text-end "
                        style={{ marginTop: 0, fontWeight: "bold" }}
                      >
                        MD (Ayu)
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    {error ||
                      (!data && (
                        <div
                          className="d-flex justify-content-center align-items-center vh-80 mt-5  "
                          style={{ alignItems: "center" }}
                          // style={{ maxWidth: 540 }}
                        >
                          <div className="row g-0">
                            <div className="col-md-4">
                              {/* <img src="..." className="img-fluid rounded-start" alt="..." /> */}
                              <FontAwesomeIcon
                                icon="fa-solid fa-triangle-exclamation"
                                style={{ color: "#FFD43B" }}
                                className="img-fluid rounded-start ps-3"
                              />
                            </div>
                            <div className="col-md-8">
                              <div className="card-body">
                                <h5 className="card-title text-centar">
                                  Data Not Found
                                </h5>
                                <p className="card-text">
                                  Sorry, we couldn't find any data matching your
                                  request. Please try again later or contact
                                  support if the issue persists.
                                </p>
                                <p className="card-text">
                                  <Link
                                    to="/tokenbooking"
                                    className="btn btn-primary"
                                  >
                                    Go To Home
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {/* footer spaces */}
              {/* <div className="border-bottom position-fixed bottom-0 w-100">

              </div> */}
            </div>
          </div>

          {/* not rekated print content */}
          {!LeftMenu && (
            <div className="col-xl-4 col-12 mb-4 mb-md-0">
              <div className="card h-100 shadow-sm">
                <div className="card-body d-flex flex-column p-4">
                  {/* Language Selection */}
                  <div className="mb-4">
                    <label
                      htmlFor="languageSelect"
                      className="form-label fw-semibold mb-2"
                    >
                      Language
                    </label>
                    <select
                      id="languageSelect"
                      className="form-select form-select-lg"
                      value={selectedLanguage}
                      onChange={handleLanguageChange}
                    >
                      <option value="en">English</option>
                      <option value="hi">Hindi</option>
                      <option value="mar">Marathi</option>
                    </select>
                  </div>

                  {/* Email Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="emailInput"
                      className="form-label fw-semibold mb-2"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      id="emailInput"
                      className="form-control form-control-lg"
                      placeholder="name@example.com"
                    />
                  </div>

                  {/* Mobile Number Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="mobileInput"
                      className="form-label fw-semibold mb-2"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      id="mobileInput"
                      className="form-control form-control-lg"
                      placeholder="Mobile Number"
                    />
                  </div>

                  {/* Action Buttons - Takes remaining space and pushes to bottom */}
                  <div className="mt-auto">
                    {/* Print Button - Full width */}
                    <button
                      className="btn btn-primary btn-lg w-100 mb-3 py-3 d-flex align-items-center justify-content-center"
                      onClick={handlePrint}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-print"
                        className="me-3"
                      />
                      <span className="fw-bold">Print Prescription</span>
                    </button>

                    {/* Share Buttons - Equal width */}
                    <div className="d-flex gap-2 mb-4">
                      <button className="btn btn-success flex-grow-1 py-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          icon="fa-brands fa-whatsapp"
                          className="me-2"
                        />
                        WhatsApp
                      </button>
                      <button className="btn btn-danger flex-grow-1 py-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          icon="fa-regular fa-envelope"
                          className="me-2"
                        />
                        Email
                      </button>
                    </div>

                    {/* View Tokens Button */}
                    <button
                      className="btn btn-outline-primary w-100 py-2"
                      onClick={() => navigate("/tokenbooking")}
                    >
                      View All Tokens
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrintDivComponent;
