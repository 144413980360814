import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import {
  ACCESS_TOKEN,
  BASE_URL,
  deleteLabReport,
  saveLabTestsandImagingData,
  SUPER_ADNIM,
  uploadLabReport,
} from "../Constant";
import axios from "axios";
import apiHelper from "../report/ApiHelpar/apiHelper";
import moment from "moment";

import swal from "sweetalert";


 export const truncateText = (text, maxLength = 50) => {
    return text?.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

const LabReportAccordion = ({
  totalAmount,
  rupee,
  recevedTokens,
  enableError,
  enableSuccess,
  LabTestPaymentData, //labrepot Data
  isLab,
  isSuprAdmin,
  isAdmin,
  fetchTests,
  isAccessible,
}) => {
  // console.log(isLab, "ss", isSuprAdmin);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState("Payment");

  const [labTestRecord, setLabTestRecord] = useState({});
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [barcode, setBarcode] = useState("");
  const [error, setError] = useState("");
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [Loading, setLoading] = useState(false);

  // Store uploaded files (local & server response)
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const now = new Date();
    setCurrentDate(now.toISOString().split("T")[0]); // Format: YYYY-MM-DD
    setCurrentTime(now.toTimeString().split(" ")[0]); // Format: HH:MM:SS
  }, []);

  useEffect(() => {
    setLabTestRecord({});
    setUploadedFiles([]);
    setBarcode("");
    setPaymentMode("Payment");

    setLabTestRecord((prevData) => ({
      ...prevData,
      ipd: LabTestPaymentData?.ipd,
      free: LabTestPaymentData?.free,
      paymentStatus: false,
      amount: LabTestPaymentData?.labTotalAmount,
      cash: LabTestPaymentData?.labCash,
      upi: LabTestPaymentData?.labUpi,
      card: LabTestPaymentData?.labCard,
      other: LabTestPaymentData?.labOther,
      discount: LabTestPaymentData?.labTotalDiscount,
    }));

    setBarcode(LabTestPaymentData?.note);

    setPaymentMode(
      LabTestPaymentData?.ipd
        ? "IPD"
        : LabTestPaymentData?.free
        ? "FREE"
        : "Payment"
    );

    if (LabTestPaymentData?.reportUrl?.length > 0) {
      const newFiles = LabTestPaymentData?.reportUrl?.map((item) => {
        const newUrl = item.split("/");
        return {
          fileDownloadUri: item,
          fileName: newUrl[newUrl?.length - 1],
          fileType: "application/pdf",
          size: "",
          status: "uploaded",
        };
      });

      setUploadedFiles((prevFiles) => [...prevFiles, ...(newFiles || [])]);
    }

    //uploaded
  }, [LabTestPaymentData]);

  const toggleAccordion = async () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectionChange = (selection) => {
    if (selection === "Payment") {
      setLabTestRecord((prevData) => ({
        ...prevData,
        ipd: false,
        free: false,
        paymentStatus: true,
      }));
    } else {
      setLabTestRecord((prevData) => ({
        ...prevData,
        ipd: selection === "IPD",
        free: selection === "FREE",
        paymentStatus: false,
        amount: "",
        cash: "",
        upi: "",
        card: "",
        other: "",
        discount: "",
      }));
    }
    setPaymentMode(selection);
  };

  // Convert payment fields to numbers safely
  const labCash = Number(labTestRecord.cash) || 0;
  const labUpi = Number(labTestRecord.upi) || 0;
  const labCard = Number(labTestRecord.card) || 0;
  const labOther = Number(labTestRecord.other) || 0;
  const labTotalDiscount = Number(labTestRecord.discount) || 0;
  const labTotalAmount = Number(totalAmount) || 0;

  // Calculate total amount paid
  const labTotalAmountPaid = labCash + labUpi + labCard + labOther;
  const totalPaidIncludingDiscount = labTotalAmountPaid + labTotalDiscount;

  const getPaymentStatus = () => {
    if (totalAmount - labTotalAmountPaid - labTotalDiscount == 0) {
      return `✅ Payment Successful! `;
    } else {
      return `⚠️ Insufficient Payment!  `;
    }
  };

  const handleSubmit = async () => {
    setError("");
    setLoading(true);
    try {
      // Filter uploaded files
      const uploadedFile = uploadedFiles.filter(
        (file) => file.status === "uploaded"
      );

      // Validate file upload
      // if (uploadedFile.length === 0) {
      //   alert("Please upload all required files.");
      //   return;
      // }

      if (labTestRecord.free == false) {
        if (labTestRecord.ipd == false) {
          if (totalPaidIncludingDiscount !== labTotalAmount) {
            setError(
              `Total payment (${totalPaidIncludingDiscount}) does not match the required amount (${labTotalAmount}).`
            );
            setLoading(false);
            return;
          }
        }
      }

      const filteredFiles = uploadedFile.map((file) =>
        file?.fileDownloadUri !== "" ? file?.fileDownloadUri : null
      );

      // Validate required fields before proceeding
      if (!recevedTokens?.tokenId) {
        setError("Token ID is required.");
        setLoading(false);
        return;
      }

      if (!currentDate) {
        setError("Test date is required.");
        setLoading(false);
        return;
      }

      // console.log("filteredFiles", filteredFiles);

      // Prepare request payload
      const data = {
        id: LabTestPaymentData?.id || null,
        note: barcode?.trim() || "",
        reportUrl: filteredFiles || null, // Contains only "uploaded" files
        free: !!labTestRecord.free, // Ensures boolean value
        ipd: !!labTestRecord.ipd, // Ensures boolean value
        labCash,
        labUpi,
        labCard,
        labOther,
        labTotalDiscount,
        labTotalAmount,
        labTotalAmountPaid,
        testDate: currentDate,
        tokenId: recevedTokens?.tokenId,
      };

      // API Call
      const response = await apiHelper("saveLabTestPayment", data);
      // console.log("API Response:", response.respData.message);

      // Handle API response
      if (response?.respData?.message) {
        console.log(response?.respData?.message);
        // Optionally reset form state after successful submission
      } else {
        setLoading(false);
        setError(response?.message || "Failed to save payment.");
      }

      SaveLabTestsandImagingData(
        filteredFiles,
        labTestRecord.free,
        labTestRecord.ipd
      );
    } catch (error) {
      setError("Error: Unable to save data.");
      setLoading(false);
      console.error("Submission Error:", error);
    }
  };

  const SaveLabTestsandImagingData = async (
    filteredFiles,
    labTestRecordfree,
    labTestRecordIpd
  ) => {
    try {
      // Determine Status
      let Status = 0; // Default Pending (0)

      if (labTotalAmountPaid > 0 && filteredFiles.length > 0) {
        Status = 2; // Complete
      } else if (labTestRecordfree || labTestRecordIpd) {
        Status = 2; // Complete
      } else if (labTotalAmountPaid > 0) {
        Status = 1; // On Going
      }

      const data = {
        tokenId: recevedTokens.tokenId,
        status: Status,
      };

      // API Call
      const response = await apiHelper(saveLabTestsandImagingData, data);
      await fetchTests();
      enableSuccess("Record saved successfully!");

      if (response?.respData?.message) {
        console.log("API Response:", response?.respData?.message);
      } else {
        enableError();

        setError(response?.message || "Failed to save payment.");
      }
    } catch (error) {
      console.error("Error saving lab test data:", error);
      setError("An error occurred while saving.");
    } finally {
      setLoading(false);
    }
  };

  const handlePrintFile = (file) => {
    if (typeof file === "string") {
      // Server URL (Cross-Origin Handling)
      const printWindow = window.open(file, "_blank");
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.focus();
          setTimeout(() => {
            printWindow.print(); // May not work due to CORS, but user can manually print
          }, 1000);
        };
      } else {
        alert("Pop-up blocked! Please allow pop-ups to print the file.");
      }
    } else {
      // Local File Handling
      if (file) {
        const fileURL = URL.createObjectURL(file);
        const printWindow = window.open(fileURL, "_blank");
        if (printWindow) {
          printWindow.onload = () => {
            printWindow.focus();
            setTimeout(() => {
              printWindow.print();
            }, 1000);
            URL.revokeObjectURL(fileURL);
          };
        } else {
          alert("Pop-up blocked! Please allow pop-ups to print the file.");
        }
      }
    }
  };

  const sanitizeFileName = (name) => {
    const extension = name.split(".").pop(); // Extract file extension
    const baseName = name.replace(/\s+/g, "_").replace(/\.[^/.]+$/, ""); // Remove spaces & extension
    return `${baseName}_${moment().format("YYYYMMDD_HHmmss")}.${extension}`; // Add timestamp
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files).map((file) => {
      const renamedFile = new File([file], sanitizeFileName(file.name), {
        type: file.type,
      });
      return {
        file: renamedFile,
        status: "pending",
      };
    });

    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    // console.log("Uploaded Files:", files);
  };

  // console.log(recevedTokens?.customerId?.customerId);

  const PDFSubmit = async (fileData, index) => {
    setIsLoading(true);
    setError("");

    try {
      const formData = new FormData();
      formData.append("file", fileData.file);
      formData.append(
        "customerId",
        recevedTokens?.customerId?.customerId || recevedTokens.customerId
      );
      formData.append("tokenId", recevedTokens?.tokenId);

      const uploadResponse = await axios.post(
        `${BASE_URL + uploadLabReport}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          },
        }
      );

      // console.log("Upload Response:", uploadResponse);

      if (!uploadResponse?.data?.fileDownloadUri) {
        // enableSuccess("Record Saved");
        setError("Failed to upload file");
        enableError();
        return;
      }

      // Update uploaded file list with the renamed file
      setUploadedFiles((prevFiles) =>
        prevFiles.map((f, i) =>
          i === index ? { ...f, ...uploadResponse.data, status: "uploaded" } : f
        )
      );
      enableSuccess("File uploaded successfully!");
    } catch (error) {
      enableError();
      //  enableError();
      setError("Upload failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFile = async (index) => {
    const fileData = uploadedFiles[index];

    // console.log("fileData", fileData);

    // Show confirmation alert using SweetAlert
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      buttons: ["Cancel", "Delete"], // Cancel and Delete buttons
      dangerMode: true,
    });

    // If the user clicks "Delete", proceed with the file deletion
    if (willDelete) {
      if (fileData.status === "uploaded") {
        try {
          // Call your delete API function here
          await DeleteLabReport(fileData.fileName, fileData.fileDownloadUri);
          swal(
            "Deleted!",
            "Your file has been deleted successfully!",
            "success"
          );
        } catch (error) {
          console.error("Error deleting file from server:", error);
          swal(
            "Error!",
            "Something went wrong while deleting the file.",
            "error"
          );
          return;
        }
      }

      // Remove the file from the uploaded files list after confirmation
      setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    } else {
      swal("Cancelled", "Your file is safe!", "info");
    }
  };

  const DeleteLabReport = async (fileName, fileDownloadUri) => {
    try {
      const formData = new FormData();
      formData.append("file", fileName);
      formData.append(
        "customerId",
        recevedTokens?.customerId?.customerId || recevedTokens.customerId
      );
      formData.append("tokenId", recevedTokens?.tokenId);
      formData.append("url", fileDownloadUri);

      const response = await axios.post(
        `${BASE_URL + deleteLabReport}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          },
        }
      );
      // console.log("File Deleted:", response.data);

      if (response.data == "File deleted successfully") {
      }
    } catch (error) {
      // enableError();
      setError("Error deleting file");
      console.error("Delete Error:", error);
    }
  };

  const preSumit = async () => {
    if (isOpen) {
      await handleSubmit();
    }
    // toggleAccordion();
  };

  const shouldShowButton =
    !isOpen ||
    labTestRecord?.ipd ||
    labTestRecord?.free ||
    totalAmount - labTotalAmountPaid - labTotalDiscount === 0;


  // console.log(LabTestPaymentData);
  console.log(uploadedFiles);
  return (
    <div className="modal-footer justify-content-start w-100 border-0">
      {/* Accordion Header */}

      <div className="d-flex align-items-center justify-content-center w-100 gap-sm-5 gap-2">
        <div
          className="d-flex align-items-center justify-content-between bg-primary rounded-2 w-100 px-2"
          style={{ height: 48 }} // Reduced height for minimal space
          onClick={toggleAccordion}
        >
          {/* Left Section: Title */}
          <div className="text-light fw-semibold flex-grow-1">
            Total Test Amount
          </div>
          {/* Middle Section: Compact Error Message */}
          {error && (
            <p className="bg-light text-primary fw-bold px-2 py-1 rounded-2 m-0">
              {error}
            </p>
          )}

          {/* Right Section: Minimal "Save" Button */}
          {shouldShowButton && (
            <button
              type="button"
              className="bg-light text-primary fw-bold border-0 rounded-2 p-0 ms-2 px-3"
              onClick={() => preSumit()}
              aria-controls="flush-collapseOne"
              style={{
                fontSize: "14px", // Smaller text for minimal space
                minWidth: "60px", // Prevents extra width
                height: "28px", // Keeps it small
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {Loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : isOpen ? (
                "Save"
              ) : (
                rupee.format(totalAmount)
              )}
            </button>
          )}
        </div>
        <div
          className="d-flex align-items-center justify-content-between bg-primary rounded-2 w-100 px-2"
          style={{ height: 48, flex: 1 }} // Reduced height for minimal space
          onClick={toggleAccordion}
        >
         
          <button
            type="button"
            className="bg-light text-primary fw-bold border-0 rounded-2 p-0 ms-2 px-3"
            // onClick={() => preSumit()}
            aria-controls="flush-collapseOne"
            style={{
              fontSize: "14px", // Smaller text for minimal space
              width: "200px", // Prevents extra width
              height: "28px", // Keeps it small
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {Loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : isOpen ? (
              "Close"
            ) : isSuprAdmin ? (
              "View Report"
            ) : isLab ? (
              "Upload / View Report"
            ) : LabTestPaymentData?.labTotalAmountPaid > 0 ? (
              "Payment Completed"
            ) : (
              "Make Payment"
            )}
          </button>
        </div>
      </div>

      {/* Accordion Body */}
      <div
        id="flush-collapseOne"
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby="flush-headingOne"
        style={{
          width: "100%",
          maxHeight: "480px", // Adjust this value as per your design requirements
          overflowY: "auto",
        }}
      >
        <div
          className="accordion-body rounded-2 my-3 px-3"
          style={{ backgroundColor: "#EEEEEE" }}
        >
          <form>
            {/* Test Details */}
            <div className="mb-3 row row-cols-5 g-2 g-lg-3">
              <div className="col">
                <label className="form-label fw-semibold">Payment Mode</label>
                <select
                  className="form-select bg-primary text-white border-0"
                  value={paymentMode}
                  onChange={(e) => handleSelectionChange(e.target.value)}
                  disabled={isAccessible()}
                >
                  <option value="Payment">Payment</option>
                  <option value="IPD">IPD</option>
                  <option value="FREE">FREE</option>
                </select>
              </div>

              {["Payment"].includes(paymentMode) && (
                <>
                  <div className="col">
                    <label htmlFor="card" className="form-label fw-semibold">
                      Card
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="card"
                      value={labTestRecord.card || ""}
                      onChange={(e) =>
                        setLabTestRecord({
                          ...labTestRecord,
                          card: e.target.value,
                        })
                      }
                      disabled={isAccessible()}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="cash" className="form-label fw-semibold">
                      Cash
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="cash"
                      value={labTestRecord.cash || ""}
                      onChange={(e) =>
                        setLabTestRecord({
                          ...labTestRecord,
                          cash: e.target.value,
                        })
                      }
                      disabled={isAccessible()}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="upi" className="form-label fw-semibold">
                      UPI
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="upi"
                      value={labTestRecord.upi || ""}
                      onChange={(e) =>
                        setLabTestRecord({
                          ...labTestRecord,
                          upi: e.target.value,
                        })
                      }
                      disabled={isAccessible()}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="other" className="form-label fw-semibold">
                      Other
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="other"
                      value={labTestRecord.other || ""}
                      onChange={(e) =>
                        setLabTestRecord({
                          ...labTestRecord,
                          other: e.target.value,
                        })
                      }
                      disabled={isAccessible()}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="mb-3 row row-cols-4 g-2 g-lg-3">
              {shouldShowButton && (
                <div className="col">
                  <label className="form-label text-black">Report`s</label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={handleFileUpload}
                    accept="application/pdf"
                  />
                </div>
              )}

              <div className="col">
                <label htmlFor="barcode" className="form-label fw-semibold">
                  Notes
                </label>

                <textarea
                  type="text"
                  className="form-control"
                  id="barcode"
                  rows="1"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                ></textarea>
              </div>

              {["Payment"].includes(paymentMode) && (
                <div className="col">
                  <label
                    htmlFor="discountPrice"
                    className="form-label fw-semibold"
                  >
                    Discount Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="discountPrice"
                    value={labTestRecord.discount || ""}
                    onChange={(e) =>
                      setLabTestRecord({
                        ...labTestRecord,
                        discount: e.target.value,
                      })
                    }
                    disabled={isAccessible()}
                  />
                </div>
              )}

              <div className="col">
                <ul className="list-unstyled">
                  <li>
                    <strong>Total Amount:</strong> ₹{totalAmount}
                  </li>
                  <li>
                    <strong>Paid Price:</strong> ₹{labTotalAmountPaid}
                  </li>
                  <li>
                    <strong>Discount:</strong> ₹{labTotalDiscount}
                  </li>
                  <li className="fw-bold text-danger">
                    <strong>Remaining Amount:</strong> ₹
                    {totalAmount - labTotalAmountPaid - labTotalDiscount}
                    <br></br>
                    <strong
                      className={`   ${
                        totalAmount - labTotalAmountPaid - labTotalDiscount == 0
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {totalAmount ==
                      totalAmount - labTotalAmountPaid - labTotalDiscount
                        ? null
                        : getPaymentStatus()}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>

        <div
          id="flush-collapseOne"
          className={`accordion-collapse collapse ${
            isSuprAdmin || isAdmin ? "show" : isOpen ? "show" : ""
          }`}
          aria-labelledby="flush-headingOne"
          style={{ width: "100%" }}
        >
          <div
            className="accordion-body rounded-2 my-3"
            style={{
              backgroundColor: "#EEEEEE",
            }}
          >
            {/* Uploaded Files Table */}
            {uploadedFiles?.length > 0 && (
              <div style={{ marginBottom: 50 }}>
                {/* <h5 className="fw-semibold">Uploaded Files</h5> */}
                <table className="table table-bordered text-center align-middle ">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>File Size (KB)</th>
                      <th>Preview</th>
                      <th>Print</th>
                      <th>Remove</th>
                      <th>Upload file</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedFiles.map((fileData, index) => (
                      <tr key={index}>
                        <td>
                          {truncateText(
                            fileData.file?.name || fileData?.fileName
                          )}
                        </td>

                        <td>
                          {(
                            (fileData.file?.size || fileData?.size) / 1024
                          ).toFixed(2)}
                        </td>

                        <td>
                          <a
                            href={
                              fileData?.file
                                ? URL.createObjectURL(fileData.file) // Use createObjectURL only if file exists
                                : fileData?.fileDownloadUri || "#"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              if (
                                !fileData?.fileDownloadUri &&
                                !fileData?.file
                              ) {
                                e.preventDefault(); // Prevent opening an invalid link
                                alert("No valid file to preview!");
                              }
                            }}
                          >
                            Preview
                          </a>
                        </td>
                        <td>
                          <button
                            className="btn btn-success "
                            onClick={(e) => {
                              e.preventDefault(); // Prevent form submission
                              e.stopPropagation();
                              fileData.status == "uploaded"
                                ? handlePrintFile(fileData.fileDownloadUri)
                                : handlePrintFile(fileData.file);
                            }}
                          >
                            Print
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-danger "
                            onClick={(e) => {
                              e.preventDefault(); // Prevent form submission
                              e.stopPropagation();
                              handleRemoveFile(index);
                            }}
                          >
                            Remove
                          </button>
                        </td>

                        <td>
                          {fileData.status === "pending" ? (
                            <button
                              className="btn btn-outline-primary"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent form submission
                                e.stopPropagation();
                                setUploadingIndex(index); // Set loading only for this file
                                PDFSubmit(fileData, index);
                              }}
                            >
                              {isLoading && uploadingIndex === index ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                              ) : (
                                "Upload"
                              )}
                            </button>
                          ) : (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              style={{ color: "#28a745" }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabReportAccordion;
