import React, { useEffect, useState } from "react";

const FileListComponent = ({ files = [] }) => {
  const [UploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    console.log(files, "files");
    if (files?.reportUrl?.length > 0) {
      const newFiles = files?.reportUrl?.map((item) => {
        const newUrl = item.split("/");
        return {
          fileDownloadUri: item,
          fileName: newUrl[newUrl?.length - 1],
          fileType: "application/pdf",
          size: "",
          status: "uploaded",
        };
      });

      setUploadedFiles((prevFiles) => [...prevFiles, ...(newFiles || [])]);
    }
  }, [files]);
  // Function to truncate text with ellipsis
  const truncateText = (text, maxLength = 20) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  // Function to handle file printing
  const handlePrintFile = (file) => {
    if (typeof file === "string") {
      // Server URL (Cross-Origin Handling)
      const printWindow = window.open(file, "_blank");
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.focus();
          setTimeout(() => {
            printWindow.print(); // May not work due to CORS, but user can manually print
          }, 1000);
        };
      } else {
        alert("Pop-up blocked! Please allow pop-ups to print the file.");
      }
    } else {
      // Local File Handling
      if (file) {
        const fileURL = URL.createObjectURL(file);
        const printWindow = window.open(fileURL, "_blank");
        if (printWindow) {
          printWindow.onload = () => {
            printWindow.focus();
            setTimeout(() => {
              printWindow.print();
            }, 1000);
            URL.revokeObjectURL(fileURL);
          };
        } else {
          alert("Pop-up blocked! Please allow pop-ups to print the file.");
        }
      }
    }
  };

  return (
    <div style={{ marginBottom: 50 }}>
      {/* <h5 className="fw-semibold">Uploaded Files</h5> */}
      <table className="table table-bordered text-center align-middle ">
        <thead>
          <tr>
            <th>File Name</th>
            <th>File Size (KB)</th>
            <th>Preview</th>
            <th>Print</th>
          </tr>
        </thead>
        <tbody>
          {UploadedFiles.map((fileData, index) => (
            <tr key={index}>
              <td>{truncateText(fileData.file?.name || fileData?.fileName)}</td>
              <td>
                {((fileData.file?.size || fileData?.size) / 1024).toFixed(2)}
              </td>
              <td>
                <a
                  href={
                    fileData?.file
                      ? URL.createObjectURL(fileData.file)
                      : fileData?.fileDownloadUri || "#"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    if (!fileData?.fileDownloadUri && !fileData?.file) {
                      e.preventDefault();
                      alert("No valid file to preview!");
                    }
                  }}
                >
                  Preview
                </a>
              </td>
              <td>
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePrintFile(fileData.fileDownloadUri);
                  }}
                >
                  Print
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FileListComponent;
