
import React, { useState, useEffect } from "react";
import { ROLE, ROLE_LAB, SUPER_ADNIM, USER_INFO } from "../../Constant";
 import apiHelper from "../../report/ApiHelpar/apiHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFileDownload,
  faSyncAlt,
  faSearch,
  faFileMedical,
} from "@fortawesome/free-solid-svg-icons";
import { UserReportRow } from "./UserReportRow";


const UserLabTestReports = () => {
  const [state, setState] = useState({
    reports: [],
    loading: true,
    error: null,
    searchTerm: "",
    currentPage: 1,
    pageSize:10,
    totalPages: 0,
    totalElements: 0,
  });

  const [isAdmin, setIsAdmin] = useState(false);
  const userData = JSON.parse(localStorage.getItem(USER_INFO) || "{}");

//   const fetchReports = async (page = 1) => {
//     try {
//       setState((prev) => ({ ...prev, loading: true, error: null }));

//       const offset = (page - 1) * state.pageSize;
//       const requestData = {
//         pageSize: state.pageSize,
//         offset,
//         ...(isAdmin && { name: state.searchTerm }),
//         mobileNo: isAdmin ? undefined : userData.mobileNo, // Use actual user mobile
//       };

         
//       // Clean request data
//       Object.keys(requestData).forEach(
//         (key) => requestData[key] === undefined && delete requestData[key]
//       );

//       const response = await apiHelper("fetchAllReportUrl", requestData);

   
//       if (!response?.respData?.respMsg) {
//         throw new Error("Invalid API response structure");
//       }

//       const { content, totalPages, totalElements } = response.respData.respMsg;
//       setState((prev) => ({
//         ...prev,
//         reports: content || [],
//         totalPages,
//         totalElements,
//         currentPage: page,
//         loading: false,
//       }));
//     } catch (error) {
//       console.error("Fetch error:", error);
//       setState((prev) => ({
//         ...prev,
//         error: error.message || "Failed to load reports",
//         loading: false,
//       }));
//     }
//   };


const fetchReports = async (page = 1) => {
  try {
    setState((prev) => ({ ...prev, loading: true, error: null }));

    // Determine admin status synchronously
    const userRoles = userData.role?.split(",") || [];
    const checkAdmin = userRoles.some((role) =>
      [ROLE, ROLE_LAB, SUPER_ADNIM].includes(role)
    );

  

    const offset = (page - 1) * state.pageSize;
    const requestData = {
      pageSize: state.pageSize,
      offset,
      ...(checkAdmin && { name: state.searchTerm }),
      mobileNo: checkAdmin ? undefined : userData.mobileNo,
    };

    // Clean request data
    Object.keys(requestData).forEach(
      (key) => requestData[key] === undefined && delete requestData[key]
    );

    const response = await apiHelper("fetchAllReportUrl", requestData);

    if (!response?.respData?.respMsg) {
      throw new Error(response?.respData?.respMsg);
    }

    const { content, totalPages, totalElements } = response.respData.respMsg;
    setState((prev) => ({
      ...prev,
      reports: content || [],
      totalPages,
      totalElements,
      currentPage: page,
      loading: false,
    }));

    // Update admin state after successful fetch
    setIsAdmin(checkAdmin);
  } catch (error) {
    console.error("Fetch error:", error);
    setState((prev) => ({
      ...prev,
      error: error.message || "Failed to load reports",
      loading: false,
    }));
  }
};


  useEffect(() => {

 
    fetchReports();
  }, []);

  // Handlers
  const handleSearch = (e) => {
    e.preventDefault();
    fetchReports(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= state.totalPages) {
      fetchReports(newPage);
    }
  };


  const handleReportAction = (action, report, url) => {
    if (!url) return;

    if (action === "view") {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      // Improved download logic
      const filename = url.split("/").pop();
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  };
  
  
  
  const renderLoader = () => (
    <tr>
      <td colSpan="4" className="text-center py-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <p className="mt-2 text-muted">Fetching your reports...</p>
      </td>
    </tr>
  );

  const renderError = () => (
    <tr>
      <td colSpan="4" className="text-center py-4 text-danger">
        <FontAwesomeIcon icon="exclamation-circle" size="2x" />
        <p className="mt-2">{state.error}</p>
        <button
          className="btn btn-outline-primary mt-2"
          onClick={() => fetchReports(state.currentPage)}
        >
          <FontAwesomeIcon icon={faSyncAlt} /> Retry
        </button>
      </td>
    </tr>
  );

  const renderEmptyState = () => (
    <tr>
      <td colSpan="4" className="text-center py-4 text-muted">
        <FontAwesomeIcon icon={faFileMedical} size="2x" className="mb-3" />
        <p className="mb-0">No reports found matching your criteria</p>
        {isAdmin && (
          <p className="text-muted mt-2">
            Try adjusting your search terms or check filters
          </p>
        )}
      </td>
    </tr>
  );

  return (
    <div className="container-fluid py-4 " style={{ marginTop: 100 }}>
      <div className="row justify-content-center">
        <div className="col-12 col-xl-10">
          <div className="card shadow-sm">
            <div className="card-header bg-white py-3 d-flex justify-content-between align-items-center">
              <div>
                <h3 className="mb-0 fw-bold text-primary">
                  Medical Reports
                  <span className="badge bg-primary ms-3">
                    {state.totalElements}
                  </span>
                </h3>
                {isAdmin && (
                  <p className="text-muted mb-0 mt-1 small">
                    Showing results for: {state.searchTerm || "All patients"}
                  </p>
                )}
              </div>

              <div className="d-flex gap-2">
                {isAdmin && (
                  <form onSubmit={handleSearch} className="me-3">
                    <div className="input-group">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search patients..."
                        value={state.searchTerm}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            searchTerm: e.target.value,
                          }))
                        }
                        aria-label="Search reports"
                      />
                      <button
                        className="btn btn-outline-primary"
                        type="submit"
                        disabled={state.loading}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </form>
                )}

                <button
                  className="btn btn-primary"
                  onClick={() => fetchReports(state.currentPage)}
                  disabled={state.loading}
                >
                  <FontAwesomeIcon
                    icon={faSyncAlt}
                    spin={state.loading}
                    className="me-2"
                  />
                  {state.loading ? "Refreshing..." : "Refresh"}
                </button>
              </div>
            </div>

            <div className="card-body p-4">
              <div className="table-responsive rounded-3">
                <table className="table table-hover align-middle mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th className="w-25">Report ID</th>
                      <th className="w-25">Date</th>
                      <th className="w-30">Patient Name</th>
                      <th className="w-20 text-end">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.error
                      ? renderError()
                      : state.loading
                      ? renderLoader()
                      : state.reports.length === 0
                      ? renderEmptyState()
                      : state.reports.flatMap((report) => {
                          const urls =
                            report?.labTestPaymentData?.reportUrl || [];

                          // If no reports, show one disabled row
                          if (urls.length === 0) {
                            return (
                              <UserReportRow
                                key={report.tokenId}
                                report={report}
                                url={null}
                                index={0}
                                handleReportAction={handleReportAction}
                              />
                            );
                          }

                          // Map each URL to a separate row
                          return urls.map((url, index) => (
                            <UserReportRow
                              key={`${report.tokenId}-${index}`}
                              report={report}
                              url={url}
                              index={index}
                              handleReportAction={handleReportAction}
                            />
                          ));
                        })}
                  </tbody>
                </table>
              </div>

              {state.totalPages > 1 && (
                <nav className="mt-4 d-flex justify-content-end">
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        state.currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(state.currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>

                    {[...Array(state.totalPages).keys()].map((page) => (
                      <li
                        key={page + 1}
                        className={`page-item ${
                          state.currentPage === page + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page + 1)}
                        >
                          {page + 1}
                        </button>
                      </li>
                    ))}

                    <li
                      className={`page-item ${
                        state.currentPage >= state.totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(state.currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLabTestReports;
