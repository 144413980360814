

import React from "react";


const AdvertisementTable = ({ advertisements, onSelect }) => {
  return (
    <div className="container my-4">
      <table className="table table-bordered table-striped">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Description</th>
            <th>Start Date</th>
            <th>Video Duration</th>
            <th>Position</th>
            <th>Size</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {advertisements.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.title}</td>
              <td>{item.description}</td>
              <td>
                {item.startDate
                  ? new Date(item.startDate).toLocaleDateString()
                  : "N/A"}
              </td>
              <td>
                {item.videoDuration
                  ? item.videoDuration.includes(":")
                    ? `${item.videoDuration} minutes`
                    : `${item.videoDuration} seconds`
                  : "N/A"}
              </td>
              <td>{item.position || "N/A"}</td>
              <td>{item.size || "N/A"}</td>
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onSelect(item)}
                >
                  Select
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdvertisementTable;

