import AddContent from "./AddContent";
import React, { useEffect, useState } from "react";

const Advertisement = ({
  position = "center", // Options: top-left, top-center, top-right, middle-left, center, middle-right, bottom-left, bottom-center, bottom-right
  size = "medium", // Options: small, medium, large, extra-large
  content = {}, // Default content
  onClose = () => {}, // Callback for closing the advertisement
  adDataclear = false,
}) => {
  const [isVisible, setIsVisible] = useState(true); // Controls component visibility
  const [isClosing, setIsClosing] = useState(false); // Controls exit animation

  const handleClose = () => {
    setIsClosing(true); // Trigger exit animation
    setTimeout(() => {
      setIsVisible(false); // Remove component after animation
      onClose(); // Call onClose callback
    }, 600); // Match the CSS animation duration
  };

  useEffect(() => {
    if (adDataclear) {
      handleClose();
    }
  }, [adDataclear]);

  if (!isVisible) return null;

  // Map positions to CSS classes
  const positionClasses = {
    "top-left": "position-fixed top-0 left-0",
    "top-center": "position-fixed top-0 left-1/2 transform -translate-x-1/2",
    "top-right": "position-fixed top-0 right-0",
    "middle-left": " position-fixed top-1/2 left-0 transform -translate-y-1/2",
    center:
      " position-fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
    "middle-right":
      " position-fixed top-1/2 right-0 transform -translate-y-1/2",
    "bottom-left": " position-fixed bottom-0 left-0",
    "bottom-center":
      " position-fixed bottom-0 left-1/2 transform -translate-x-1/2",
    "bottom-right": "position-fixed bottom-0 end-0",
  };

  // Map sizes to styles
  const sizeStyles = {
    small: { width: "30%", height: "auto" },
    medium: { width: "50%", height: "auto" },
    large: { width: "70%", height: "auto" },
    "extra-large": { width: "100%", height: "100%" },
  };

  return (
    <>
      {/* Embedded CSS for animations */}
      <style>{`
        .advertisement-enter {
          animation: zoomIn 0.6s forwards ease-out;
        }

        .advertisement-exit {
          animation: zoomOut 0.6s forwards ease-in;
        }

        @keyframes zoomIn {
          from {
            transform: scale(0.8);
            opacity: 0;
          }
          to {
            transform: scale(1);
            opacity: 1;
          }
        }

        @keyframes zoomOut {
          from {
            transform: scale(1);
            opacity: 1;
          }
          to {
            transform: scale(0.8);
            opacity: 0;
          }
        }

   

          /* Transparent background but allows clicks to go through */
        .advertisement-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent; /* Fully transparent */
          z-index: 1050;
          pointer-events: none; /* Allows clicks to pass through */
        }

        .advertisement-container {
          position: absolute;
          background: white;
          border-radius: 0.75rem;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          overflow: hidden;
           pointer-events: auto; /* Allows interactions with the ad */
        }

       
      `}</style>

      {/* Advertisement Backdrop */}
      <div className="advertisement-backdrop">
        <div
          className={`advertisement-container advertisement-${
            isClosing ? "exit" : "enter"
          } ${positionClasses[position]}`}
          style={{
            ...sizeStyles[size],
          }}
        >
          {/* Advertisement Content */}
          <AddContent content={content} onClose={handleClose} size={size} />
        </div>
      </div>
    </>
  );
};

export default Advertisement;
